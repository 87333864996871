<template>
  <div id="join-page">
    <div class="header-img">
      <img src="../../../static/image/join-mod/join-us-header-bg-img.png" alt />
      <div class="infos">
        <div class="big-text">加入汉熵</div>
        <div class="fu-text">志在山顶的人，不会贪恋山腰的风景</div>
        <div class="join-type">
          <div :class="{ 'is-active': joinType === 0 }" @click="inActive(0)">社会招聘</div>
          <div :class="{ 'is-active': joinType === 1, school: true }" @click="inActive(1)">校园招聘</div>
        </div>
      </div>
    </div>
    <!-- 工作 汉熵 -->
    <div class="work-in-hanshan">
      <mod-title title="工作在汉熵" enTitle="WORK IN CHINA ENTROPY" />

      <div class="list-infos">
        <ul>
          <li :class="{ 'is-active': activeWorkPicIdx === i }" v-for="(name, i) in workPicList" :key="i" @click="selPic(i)">{{ name }}</li>
        </ul>
      </div>
      <div class="img-content">
        <workPic :showType="activeWorkPicIdx" />
      </div>
    </div>
    <!-- 汉熵 福利-->
    <div class="hanshan-welfare">
      <mod-title title="汉熵福利" enTitle="CHINA ENTROPY WELLFARE" />
      <div class="welfare-content">
        <welfare />
      </div>
    </div>
    <!-- 招聘 岗位-->
    <div class="recruitment">
      <div class="title-content">
        <mod-title title="招聘岗位" enTitle="POSITIONS" dark="1" />
      </div>
      <div class="list-infos">
        <ul>
          <li
            :class="{ 'is-active': recruitmentType === i }"
            v-for="(name, i) in recruitmentList"
            :key="i"
            @click="selRecruitmentType(i)"
          >{{ name }}</li>
        </ul>
      </div>
      <div class="recruitment-content">
        <recruitment :showType="recruitmentType" />
      </div>
    </div>
    <!-- 应聘 流程 -->
    <div class="apply-process">
      <mod-title title="应聘流程" enTitle="APPLICATION PROCESS" />
      <div class="apply-content">
        <apply-process />
      </div>
    </div>
  </div>
</template>
<script>
import modTitle from '../../components/mod-title/Index.vue'
import workPic from './modules/work-pic/Index'
import welfare from './modules/comp-welfare/Index.vue'
import recruitment from './modules/recruitment/Index.vue'
import applyProcess from './modules/apply-process/Index.vue'
export default {
  components: {
    modTitle,
    workPic,
    welfare,
    recruitment,
    applyProcess
  },
  data() {
    return {
      name: '456',
      joinType: 0,

      //workPicList: ['办公环境照片', '团建照片', '汉熵大讲堂', '汉熵精英'],
      workPicList: ['办公环境照片', '团建照片', '汉熵精英'],
      activeWorkPicIdx: 0,

      recruitmentList: ['社会招聘', '校园招聘'],
      recruitmentType: 0
    }
  },
  mounted() {},
  methods: {
    inActive(k) {
      // this.joinType = k;
      const { href } = this.$router.resolve({
        path: `/job-list/${k}/0`
      })
      window.open(href, '_blank')
      // this.$router.push(`/job-list/${k}/0`)
    },
    selPic(k) {
      this.activeWorkPicIdx = k
    },
    selRecruitmentType(k) {
      this.recruitmentType = k
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index';
</style>
