<template>
  <div class="viedo-paly">
    <div class="hide-modal" @click="$emit('update:visible', false)"></div>
    <div class="content">
      <span class="close-btn" @click="$emit('update:visible', false)"
        >关闭</span
      >
      <video class="trailer" controls autoplay>
        <source :src="videoSrc" />
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoPlayer",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    videoSrc: {
      type: String,
      default: null
    }
  },
  data() {
    return {

    };
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>