import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    solutionTree: {},
    solutionList: [],
    homeActive: 1, //首页显示的图片，用于控制header的样式
    photoViewVisible: false, //图片预览
    photoViewSrc: null,
    hardwareList:[],
    softwareList:[],
  },
  getters: {
    solutionTree(state) {
      return state.solutionTree;
    },
    solutionList(state) {
      return state.solutionList;
    },
    hardwareList(state) {
      return state.hardwareList;
    },
    softwareList(state) {
      return state.softwareList;
    },
    homeActive(state) {
      return state.homeActive;
    },
  },
  mutations: {
    SET_SOLUTION_TREE: (state, data) => {
      state.solutionTree = data;
    },
    SET_SOLUTION_LIST: (state, data) => {
      state.solutionList = data;
    },
    SET_HOME_ACTIVE: (state, data) => {
      state.homeActive = data;
    },
    SET_PHOTO_VIEW_VISIBLE: (state, data) => {
      state.photoViewVisible = data;
    },
    SET_PHOTO_VIEW_SRC: (state, data) => {
      state.photoViewSrc = data;
    },
    SET_HARDWARE_LIST: (state, data) => {
      state.hardwareList = data;
    },
    SET_SOFTWARE_LIST: (state, data) => {
      state.softwareList = data;
    },
  },
  actions: {
    setSolutionTree(context, data) {
      context.commit("SET_SOLUTION_TREE", data);
    },
    setSolutionList(context, data) {
      context.commit("SET_SOLUTION_LIST", data);
    },
    setPhotoViewVisible(context, data) {
      context.commit("SET_PHOTO_VIEW_VISIBLE", data);
    },
    setPhotoViewSrc(context, data) {
      context.commit("SET_PHOTO_VIEW_SRC", data);
    },
    setProductionHardwareList(context, data) {
      context.commit("SET_HARDWARE_LIST", data);
    },
    setProductionSoftwareList(context, data) {
      context.commit("SET_SOFTWARE_LIST", data);
    },
  },
  modules: {},
});
