<template>
  <div class="solution-whole">
    <div class="desc-list" :style="{ height: getHeightByLength() + 'px' }">
      <div
        v-for="(item, index) in descTitles"
        :key="index"
        :class="`desc${getClassByLength()}${active === index ? ' active' : ''}`"
        :style="{
          background: `url(/image/solution/${backType}-${index + 1}${
            index === active ? '-active' : ''
          }.png)`,
          backgroundSize: '100% 100%',
        }"
        @mouseenter="onMouseEnter(index)"
        @mouseleave="active = -1"
      >
        <img
          :src="`/image/solution/solution-desc-${index + 1}${
            index === active ? '-active' : ''
          }.png`"
        />
        <div class="desc-title">
          {{ item }}
        </div>
        <div class="desc-content" v-html="descTexts[index]"></div>
      </div>
    </div>
    <plan-advantage :solution="solution"></plan-advantage>
    <related-products :solution="solution"></related-products>
  </div>
</template>

<script>
import PlanAdvantage from './components/plan-advantage/Index.vue';
import RelatedProducts from './components/related-products/Index.vue';

export default {
  name: 'solutionWhole',
  components: {
    PlanAdvantage,
    RelatedProducts
  },
  props: {
    solution: {}
  },
  data() {
    return {
      active: -1,
      descTitles: [],
      descTexts: [],
      backType: 4
    };
  },
  watch: {
    solution() {
      this.getDesc();
    }
  },
  computed: {},
  mounted() {
    this.getDesc();
  },
  methods: {
    getDesc() {
      this.descTitles = [];
      this.descTexts = [];
      if (this.solution.title.startsWith('智慧环保')) {
        this.descTitles = [
          '提供三个“一”',
          '赋能四个“可”',
          '实现五个“全”'
        ];
        this.descTexts = [
          '一张网：结合多模异构物联网，进行全产业链智能化监管，提升环保体系监管能力<br>' +
          '一套数：形成环保行业一张图，提供多维度、多层面、多业务的环保信息展示<br>' +
          '一平台：为政府管理部门、各类企业提供可覆盖全产业链、全业务周期的各行业监管平台，全面提升环保业务开展能力和全过程信息化监管水平',
          '风险可预警：应用多种算法与数学模型，赋能行业风险预警能力<br>' +
          '事件可追溯：将物联设备信息与数据分析结果相结合，赋能环境突发事件追溯分析能力<br>' +
          '问题可解决：应用流程引擎灵活可配特性与指挥调度业务相结合，赋能环境执法与业务管控能力<br>' +
          '数据可统计：提供多种行业融合驾驶舱，展现业务中各环节、多场景的数据与统计结果',
          '角色全覆盖：各级生态环境机构、各类产业园区、多种行业企业<br>' +
          '管理全周期：从数据监测、业务分析到事件管控等多节点综合流程管理<br>' +
          '设备全接入：气象监测、污染物监测、水环境监测、土壤监测等多类设备应接尽接<br>' +
          '数据全贯通：多维数据融合分析破除信息孤岛，实现感知与应用无缝衔接<br>' +
          '办公全方位：PC端、移动端，及时报、随时管'
        ];
      } else {
        this.solution.descList.map((item, index) => {
          if (index % 2 === 0) {
            this.descTitles.push(item);
          } else {
            this.descTexts.push(item);
          }
        });
      }
      let len = this.descTitles.length;
      if (len === 3) {
        this.backType = 3;
      } else if (len === 5) {
        this.backType = 5;
      } else {
        this.backType = 4;
      }
      // console.log('简介', this.descTitles)
    },
    onMouseEnter(id) {
      this.active = id;
    },
    getHeightByLength() {
      let len = this.descTitles.length;
      let height = 538;
      if (len === 5) {
        height = 575;
      }
      return height;
    },
    getClassByLength() {
      let len = this.descTitles.length;
      if (len === 3) {
        return ' desc-third';
      }
      if (len === 5) {
        return ' desc-fifth';
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./Index.scss";
</style>
