<template>
  <div class="work-env-pic-content">
    <div class="work-env-pic" v-show="showType === 0">
      <div>
        <img :src="wePic1" class="wePic1" @click="preview(wePict1)" alt />
        <img :src="wePic2" class="wePic1 left" @click="preview(wePict2)" alt />
        <img :src="wePic3" class="wePic1 left" @click="preview(wePict3)" alt />
        <img :src="wePic4" class="wePic1 left" @click="preview(wePict4)" alt />
      </div>

      <div>
        <img :src="wePic5" class="wePic1 startone" @click="preview(wePict5)" alt />
        <img :src="wePic6" class="wePic1 left" @click="preview(wePict6)" alt />
        <img :src="wePic7" class="wePic1 left" @click="preview(wePict7)" alt />
      </div>
    </div>

    <div class="tuanjian" v-show="showType === 1">
      <div>
        <img :src="tjPic1" class="tjPic1" alt />
        <img :src="tjPic2" class="tjPic1 left" alt />
        <img :src="tjPic3" class="tjPic1 left" alt />
        <img :src="tjPic4" class="tjPic1 left" alt />
      </div>

      <div>
        <img :src="tjPic5" class="tjPic1 startone" alt />
        <img :src="tjPic6" class="tjPic1 left" alt />
        <img :src="tjPic7" class="tjPic1 left" alt />
      </div>
    </div>

    <div class="lecture-hall" v-show="showType === 3">
      <img :src="djtPic3" class="top-pic pic1" alt />
      <img :src="djtPic1" class="bottom-pic pic2" alt />
      <img :src="djtPic2" class="bottom-pic pic3" alt />
    </div>

    <div class="comp-personnel" v-show="showType === 2">
      <img :src="jyPic1" class="top-pic pic1" alt />
      <img :src="jyPic2" class="top-pic pic2" alt />
      <img :src="jyPic3" class="bottom-pic pic3" alt />
    </div>
  </div>
</template>
<script>
export default {
  name: 'workPic',
  props: {
    showType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      pic: require('/static/image/work-env.jpg'),

      wePic1: require('/static/image/join-mod/work-env/new1.png'),
      wePic2: require('/static/image/join-mod/work-env/new2.png'),
      wePic3: require('/static/image/join-mod/work-env/new3.png'),
      wePic4: require('/static/image/join-mod/work-env/new4.png'),
      wePic5: require('/static/image/join-mod/work-env/new5.png'),
      wePic6: require('/static/image/join-mod/work-env/new6.png'),
      wePic7: require('/static/image/join-mod/work-env/new7.png'),
      wePict1: require('/static/image/join-mod/work-env/newt1.png'),
      wePict2: require('/static/image/join-mod/work-env/newt2.png'),
      wePict3: require('/static/image/join-mod/work-env/newt3.png'),
      wePict4: require('/static/image/join-mod/work-env/newt4.png'),
      wePict5: require('/static/image/join-mod/work-env/newt5.png'),
      wePict6: require('/static/image/join-mod/work-env/newt6.png'),
      wePict7: require('/static/image/join-mod/work-env/newt7.png'),

      tjPic1: require('/static/image/join-mod/tuanjian/1.png'),
      tjPic2: require('/static/image/join-mod/tuanjian/2.png'),
      tjPic3: require('/static/image/join-mod/tuanjian/3.png'),
      tjPic4: require('/static/image/join-mod/tuanjian/4.png'),
      tjPic5: require('/static/image/join-mod/tuanjian/5.png'),
      tjPic6: require('/static/image/join-mod/tuanjian/6.png'),
      tjPic7: require('/static/image/join-mod/tuanjian/7.png'),

      jyPic1: require('/static/image/join-mod/jingying/1.png'),
      jyPic2: require('/static/image/join-mod/jingying/2.png'),
      jyPic3: require('/static/image/join-mod/jingying/3.png'),

      djtPic1: require('/static/image/join-mod/dajiangtang/1.png'),
      djtPic2: require('/static/image/join-mod/dajiangtang/2.png'),
      djtPic3: require('/static/image/join-mod/dajiangtang/3.png')
    }
  },
  mounted() {},
  methods: {
    preview(src) {
      this.$store.dispatch('setPhotoViewVisible', true)
      this.$store.dispatch('setPhotoViewSrc', src)
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index';
</style>