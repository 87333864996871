var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"patent-certificate"},[_c('mod-title',{staticClass:"text-mod",attrs:{"title":"专利证书","enTitle":"PATENT CERTIFICATE","sentenceText":"被多家世界500强企业有偿使用的专利证明了汉熵研发的前瞻性和经济价值"}}),_c('div',{staticClass:"patent-certificate-main"},[_c('div',{staticClass:"patent-left"},[_c('img',{attrs:{"src":("/image/brand-honor/patent/专利证书" + _vm.active + ".png")}})]),_c('div',{staticClass:"patent-right"},[_c('div',{staticClass:"patent-right-top"},_vm._l((_vm.list.filter(function (ref){
	var id = ref.id;

	return id<3;
})),function(item){return _c('img',{key:item.id,class:("img" + (item.id) + (_vm.active === item.id?' img-active':'')),attrs:{"src":("/image/brand-honor/patent/专利证书" + (item.id) + "-1.png")},on:{"click":function($event){_vm.active = item.id}}})}),0),_c('div',{staticClass:"patent-right-bottom"},_vm._l((_vm.list.filter(function (ref){
	var id = ref.id;

	return id>2;
})),function(item){return _c('img',{key:item.id,class:("img" + (item.id) + (_vm.active === item.id?' img-active':'')),attrs:{"src":("/image/brand-honor/patent/专利证书" + (item.id) + "-1.png")},on:{"click":function($event){_vm.active = item.id}}})}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }