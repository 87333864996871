var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"production-menu"}},[_c('div',{staticClass:"out-box"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"tab-title"},[_c('span',{on:{"click":function($event){return _vm.showProduction()}}},[_vm._v("软件产品")])]),_c('div',{staticClass:"list-box"},_vm._l((_vm.softwareList),function(item,i){return _c('div',{key:i,class:("child" + (_vm.activeCode === item.code ? ' active' : '')),on:{"click":function($event){return _vm.showProduction()},"mouseenter":function($event){return _vm.onMouseEnter(item, 0)}}},[_vm._v(_vm._s(item.title))])}),0),_c('div',{staticClass:"tab-title"},[_c('span',{on:{"click":function($event){return _vm.showProduction()}}},[_vm._v("硬件产品")])]),_c('div',{staticClass:"list-box"},_vm._l((_vm.hardwareList),function(item,i){return _c('div',{key:i,class:("child" + (_vm.activeCode === item.code ? ' active' : '')),on:{"click":function($event){return _vm.showProduction()},"mouseenter":function($event){return _vm.onMouseEnter(item, 1)}}},[_vm._v(_vm._s(item.title))])}),0)]),(!_vm.childList[0].children)?_c('div',{staticClass:"right"},_vm._l((_vm.childList),function(child,i){return _c('div',{key:i,staticClass:"list",on:{"click":function($event){return _vm.showMore(child)}}},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":child.photo
              ? child.photo
              : _vm.photoList[i]
              ? _vm.photoList[i]
              : _vm.photoList[0],"alt":""}})]),_c('div',{staticClass:"infos"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(child.title))]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(child.overview))])])])}),0):_c('div',{staticClass:"right"},[(_vm.proType == 0)?_c('div',{staticClass:"more-list-box"},_vm._l((_vm.childList),function(child,i){return _c('div',{key:i,staticClass:"box-content"},[_c('header',[_c('img',{attrs:{"src":require("../../assets/img/title-icon.png"),"alt":""}}),_vm._v(" "+_vm._s(child.title)+" ")]),_c('div',{staticClass:"child"},_vm._l((child.children),function(item,i){return _c('div',{key:i,staticClass:"child-list",on:{"click":function($event){return _vm.showMore(item)}}},[_vm._v(_vm._s(item.title))])}),0)])}),0):_c('div',{staticClass:"hardware-more-list"},[_c('div',{staticClass:"left"},_vm._l((_vm.childList),function(item,i){return _c('div',{key:i,class:("child" + (_vm.hardwareImgActive === i ? ' active' : '')),on:{"mouseenter":function($event){return _vm.onMouseEnterHardWare(item, i)}}},[_c('img',{attrs:{"src":_vm.hardwareImgList[
                ("" + (_vm.hardwareImgActive === i ? 'active' : 'normal') + (i + 1))
              ],"alt":""}}),_vm._v(" "+_vm._s(item.title)+" ")])}),0),_c('div',{staticClass:"right"},_vm._l((_vm.hardWareChildList),function(child,i){return _c('div',{key:i,staticClass:"list",on:{"click":function($event){return _vm.showMore(child)}}},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":child.photo
                  ? child.photo
                  : _vm.photoList[i]
                  ? _vm.photoList[i]
                  : _vm.photoList[0],"alt":""}})]),_c('div',{staticClass:"infos"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(child.title))]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(child.overview))])])])}),0)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }