import API from './index.js';
const Api = '/api'

// 获取已选择城市
export function getSelectedCity(params) {
    return API({
        url: Api+"/district/getSelected",
        method: "GET",
        params
    })
}

// 获取职位类别级联信息
export function getJobTree(params) {
    return API({
        url: Api+"/JobController/getJobTree",
        method: "GET",
        params
    })
}

// 职位类别数量
export function getJobCountGroup(params) {
    return API({
        url: Api+"/JobController/getJobCountGroup",
        method: "GET",
        params
    })
}

// 招聘岗位信息列表分页
export function getJobInfoList(params) {
    return API({
        url: Api+"/Recruitment/getInfoList",
        method: "GET",
        params
    })
}

// 上传简历
export function resume(data) {
    return API({
        url: Api+"/resume/upload/resume",
        method: "post",
        data
    })
}