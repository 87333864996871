<template>
  <div class="cooperation">
    <mod-title
      class="text-mod"
      title="产学研合作"
      enTitle="INDUSTRY UNIVERSITY RESEARCH COOPERATION"
      titleColor="#fff"
      dark="1"
      sentenceText="与国内顶级院校、世界级企业强强联手，产学研结合带动科研储备"
    />
    <div class="list">
      <!--            <img :src="require(`@/assets/img/brand-honor/cooperation-${item.id}${active === item.id?'-active':''}.png`)">-->

      <div
        v-for="item in list"
        :key="item.id"
        :class="`item ${active === item.id?'active':''}`"
        :style="{order: getOrder(item.id)}"
        @click="active = item.id"
      >
        <img
          :src="`/image/brand-honor/cooperation/${item.name}${active === item.id?'-active':''}.png`"
          :class="`${active === item.id?'active':''}`"
          @click="active = item.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import modTitle from '../../../../components/mod-title/Index.vue'

export default {
  name: 'PropertyRights',
  components: {
    modTitle
  },
  data() {
    return {
      active: 1,
      list: [
        {
          id: 1,
          name: '北理工'
        },
        {
          id: 2,
          name: '清华'
        },
        {
          id: 3,
          name: '戴尔'
        }
      ]
    }
  },
  methods: {
    getOrder(id) {
      let order = 0
      if (id === this.active) {
        order = 1
      } else if (id < this.active) {
        if (id === this.active - 2) {
          order = 2
        } else {
          order = 0
        }
      } else {
        if (id === this.active + 1) {
          order = 2
        } else {
          order = 0
        }
      }
      return order
    }
  }
}
</script>
<style lang="scss" scoped>
@import './Index.scss';
</style>
