<template>
  <div class="apply-content-infos">
    <template v-for="item in list">
      <div class="step-list" @mouseenter="active = item.num" :key="item.num">
        <span>{{ item.num }}</span>
        <img
          :src="
            require(`../../../../assets/img/step${
              active === item.num ? '-active' : ''
            }.png`)
          "
          alt
        />
        <div :class="{ title: true, 'blue-text-color': active === item.num }">
          {{ item.title }}
        </div>
        <div
          :class="{ 'min-title': true, 'blue-text-color': active === item.num }"
        >
          {{ item.minTitle }}
        </div>
      </div>
      <div
        class="line-step"
        :key="'line' + item.num"
        v-if="item.num !== 5"
      ></div>
    </template>
    <div class="join-us-btn" @click="job">立即加入！</div>
  </div>
</template>
<script>
export default {
  name: 'applyProcess',
  data() {
    return {
      list: [
        {
          num: 1,
          title: '简历投递',
          minTitle: '邮箱投递简历'
        },
        {
          num: 2,
          title: '简历筛选',
          minTitle: '投递简历筛选'
        },
        {
          num: 3,
          title: '面试',
          minTitle: '面试3-4轮'
        },
        {
          num: 4,
          title: 'OFFER',
          minTitle: '意向书/OFFER'
        },
        {
          num: 5,
          title: '入职',
          minTitle: '员工入职'
        }
      ],
      active: 1,
    };
  },
  methods: {
    job() {
      const {
        href
      } = this.$router.resolve({
        path: `/job-list/0/0`
      });
      window.open(href, '_blank');
      // this.$router.push('/job-list/0/0');
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index";
</style>