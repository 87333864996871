<template>
  <el-dialog
    class="photo-view"
    custom-class="photo-view-content"
    :visible.sync="visible"
    width="1600px"
    center
    @close="close"
    top="15px"
    @click="$store.dispatch('setPhotoViewVisible', false)"
  >
    <el-image
      :src="photoViewSrc"
      @click="$store.dispatch('setPhotoViewVisible', false)"
    ></el-image>
  </el-dialog>
</template>

<script>
export default {
  name: "PhotoView",
  props: {
    photoViewVisible: {
      type: Boolean,
      default: false,
    },
    photoViewSrc: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      visible: this.photoViewVisible
    };
  },
  methods: {
    close() {
      this.$store.dispatch('setPhotoViewVisible', false);
      // $store.dispatch('setPhotoViewSrc', null);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>