<template>
  <div id="solution-menu">
    <div class="box-out">
      <div class="left">
        <div v-for="item in list" :key="item.id" :class="`item${item.id === active?' active':''}`" @mouseenter="onMouseEnter(item)">
          <img :src="`/image/solution/${item.name}${item.id === active ? '1':'2'}.png`" />
          {{ item.name }}
        </div>
      </div>
      <div v-if="active === 1" class="center">
        <div
          v-for="item in list[0].codes"
          :key="item.code"
          @mouseover="onMouseEnter(item,true)"
          :class="`item${item.code === activeProduct?' active':''}`"
        >
          <img :src="`/image/solution/${item.name}${item.code === activeProduct ? '1':'2'}.png`" />
          {{ item.name }}
        </div>
      </div>
      <div class="right">
        <div class="mod-title">
          <img src="/image/solution/mod-icon.png" />
          解决方案
        </div>
        <div class="solution-detail">
          <img :src="activeProductObj.photo" @click="toSolution(activeProductObj)" />
          <div class="solution-detail-text">
            <div class="title" @click="toSolution(activeProductObj)">{{ activeProductObj.title }}</div>
            <div class="overview" @click="toSolution(activeProductObj)">{{ activeProductObj.overview.split('\r\n')[0] }}</div>
          </div>
        </div>
        <div v-if="softWare.length > 0" class="mod-title" style="margin-top: 32px">
          <img src="/image/solution/mod-icon.png" />
          软件产品
        </div>
        <div class="product-list">
          <div v-for="item in softWare" :key="item.code" class="product">
            <img :src="item.photo?item.photo:'/image/solution/solution-default.jpg'" @click="toProductDetail(item)" />
            <div class="product-right">
              <div class="product-name" @click="toProductDetail(item)">{{ item.title }}</div>
              <div class="product-content" @click="toProductDetail(item)">{{ item.overview }}</div>
            </div>
          </div>
        </div>
        <div v-if="hardWare.length > 0" class="mod-title" style="margin-top: 20px">
          <img src="/image/solution/mod-icon.png" />
          硬件产品
        </div>
        <div class="product-list">
          <div v-for="item in hardWare" :key="item.code" class="product">
            <img :src="item.photo?item.photo:'/image/solution/solution-default.jpg'" @click="toProductDetail(item)" />
            <div class="product-right">
              <div class="product-name" @click="toProductDetail(item)">{{ item.title }}</div>
              <div class="product-content" @click="toProductDetail(item)">{{ item.overview }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getProductByCode } from '@/api/solution.js'

export default {
  name: 'solutionMenu',
  props: {},
  computed: {
    tree() {
      return this.$store.getters.solutionTree
    },
    solutionList() {
      return this.$store.getters.solutionList
    },
    activeProductObj() {
      return this.solutionList.find(({ code }) => code === this.activeProduct)
    }
  },
  data() {
    return {
      active: 1,
      activeProduct: 'e97b6444d35246bba16f3cb9fc101bcc',
      lastClickProduct: '',
      list: [
        {
          id: 1,
          name: '生态环境',
          isMultiple: true,
          codes: [
            {
              name: '智慧林草',
              code: 'e97b6444d35246bba16f3cb9fc101bcc'
            },
            {
              name: '碳中和',
              code: 'a760f5ce7b3b4d74862b3586c9db16d9'
            }
          ]
        },
        {
          id: 2,
          name: '环境保护',
          isMultiple: false,
          code: '77842f08617940aabb8a82e351b2f195'
        },
        {
          id: 3,
          name: '城管市政',
          isMultiple: false,
          code: 'a8680054120343529a7896d12bd56be2'
        },
        {
          id: 4,
          name: '公共安全',
          isMultiple: false,
          code: 'c0971fe5f57245e4841cfa33881de343'
        },
        {
          id: 5,
          name: '水利水务',
          isMultiple: false,
          code: 'de3faedd27524a3d9390cf3d2eb836c2'
        },
        {
          id: 6,
          name: '工地建筑',
          isMultiple: false,
          code: '0695ca4ea9104511b5dfef0fc1f72e1a'
        },
        {
          id: 7,
          name: '应急管理',
          isMultiple: false,
          code: '90e8dbed253e4afc8e3f63ea37672846'
        },
        {
          id: 8,
          name: '校园管理',
          isMultiple: false,
          code: 'a26c973e4dfe4d7c998cf0cbe0e27a67'
        }
      ],
      softWare: [],
      hardWare: []
    }
  },
  mounted() {
    // 当前url
    console.log(this.$route.path)
    // this.active = this.tree.length ? this.tree[0].code : '001'
    this.activeProduct = this.tree.length ? this.tree[0].children[0].code : 'e97b6444d35246bba16f3cb9fc101bcc'
    // console.log(222,this.activeProduct,this.solutionList,this.solutionList.find(({code}) => code === this.activeProduct))
    console.log(this.activeProductObj)
    this.getProductByCode(this.activeProductObj)
    // this.lastClickProduct = this.activeProduct
  },
  methods: {
    onMouseEnter(item, isLeaf) {
      if (isLeaf) {
        this.active = 1
        this.activeProduct = item.code
        this.getProductByCode(item)
      } else {
        this.active = item.id
        this.activeProduct = item.isMultiple ? item.codes[0].code : item.code
        this.getProductByCode(item)
      }
      document.getElementsByClassName('right')[0].scrollTop = 0
    },
    onMouseLeave() {
      // this.activeProduct = this.lastClickProduct
      // this.lastClick = id
    },
    toSolution(item) {
      // this.lastClickProduct =
      this.$emit('close')
      this.activeProduct = item.code
      this.$router.push(`/solution/${item.title}`).catch(err => err)
    },
    getProductByCode(item) {
      getProductByCode({ SchemeCode: item.code }).then(res => {
        this.softWare = res.data.softWare.map(item => {
          return { ...item, proType: 1 }
        })
        this.hardWare = res.data.hardWare.map(item => {
          return { ...item, proType: 2 }
        })
      })
    },
    // 跳转产品详情页面
    toProductDetail(item) {
      this.$emit('close')
      if (item.proType === 2) {
        this.$router.push(`/hardware-product-detail/${item.code}/${item.title}`)
      } else {
        this.$router.push(`/software-product-detail/${item.code}`).catch(err => err)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import './Index.scss';
</style>
