var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"software-product-detail"},[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"banner-container"},[_c('p',{staticClass:"banner-content-title"},[_vm._v(_vm._s(_vm.title))])])]),_c('div',{staticClass:"product-overview"},[_c('mod-title',{staticClass:"text-mod",attrs:{"title":"产品概述","enTitle":"PRODUCT OVERVIEW"}}),_c('div',{staticClass:"product-overview-container"},[_c('p',[_vm._v(_vm._s(_vm.overview))]),(_vm.functionTitles.length == 3)?_c('ul',{staticClass:"product-overview-list1"},_vm._l((_vm.functionTitles),function(item,index){return _c('li',{key:index,class:{
            'product-overview-item': true,
            'product-overview-item-first': index === 0,
            'product-overview-item-second': index === 1,
            'product-overview-item-thrid': index === 2,
            'product-overview-item-active': _vm.productOverviewActive === index,
          },style:({
            backgroundImage: ("url('" + (_vm.images['Image3' + index]) + "')"),
          }),on:{"mouseenter":function($event){_vm.productOverviewActive = index}}},[_c('img',{attrs:{"src":_vm.productOverviewActive === index
                ? _vm.images['IconActive' + index]
                : _vm.images['Icon' + index],"alt":""}}),_c('p',{staticClass:"title"},[_vm._v(_vm._s(item))]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.functionTexts[index]))])])}),0):(_vm.functionTitles.length == 5)?_c('ul',{staticClass:"product-overview-list3"},_vm._l((_vm.functionTitles),function(item,index){return _c('li',{key:index,class:{
            'product-overview-item': true,
            'product-overview-item-first': index === 0,
            'product-overview-item-second': index === 1,
            'product-overview-item-thrid': index === 2,
            'product-overview-item-fourth': index === 3,
            'product-overview-item-fifth': index === 4,
            'product-overview-item-active': _vm.productOverviewActive === index,
          },style:({backgroundImage: ("url(" + (_vm.images['Image5' + index + (_vm.productOverviewActive === index ? 'Active':'')]) + ")")}),on:{"mouseenter":function($event){_vm.productOverviewActive = index}}},[_c('img',{attrs:{"src":_vm.productOverviewActive === index
                ? _vm.images['IconActive' + index]
                : _vm.images['Icon' + index],"alt":""}}),_c('p',{staticClass:"title"},[_vm._v(_vm._s(item))]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.functionTexts[index]))])])}),0):_c('ul',{staticClass:"product-overview-list2"},_vm._l((_vm.functionTitles),function(item,index){return _c('li',{key:index,class:{
            'product-overview-item': true,
            'product-overview-item-active': _vm.productOverviewActive === index,
          },style:({
            backgroundImage: ("url('" + (_vm.images['Image4' + index]) + "')"),
          }),on:{"mouseenter":function($event){_vm.productOverviewActive = index}}},[_c('img',{attrs:{"src":_vm.productOverviewActive === index
                ? _vm.images['IconActive' + index]
                : _vm.images['Icon' + index],"alt":""}}),_c('p',{staticClass:"title"},[_vm._v(_vm._s(item))]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.functionTexts[index]))])])}),0)])],1),_c('div',{staticClass:"program-advantages"},[_c('mod-title',{staticClass:"text-mod",attrs:{"title":"产品优势","enTitle":"PROGRAM ADVANTAGES","titleColor":"#ffffff","enTitleColor":"#c8d3ed30","dark":"1"}}),_c('div',{staticClass:"program-advantages-container"},[_c('ul',{staticClass:"program-advantages-list"},_vm._l((_vm.advantageTitles),function(item,index){return _c('li',{key:index,class:{
            'program-advantages-item': true,
            'program-advantages-item-active':
              _vm.programDdvantagesActive === index,
          },style:({
            backgroundImage: ("url('" + (_vm.programDdvantagesActive === index
                ? _vm.images['Image2' + index + 'Active']
                : _vm.images['Image2' + index]) + "')"),
          }),on:{"mouseenter":function($event){_vm.programDdvantagesActive = index}}},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item))]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.advantageTexts[index]))])])}),0)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }