<template>
  <div id="app">
    <div ref="container" id="app-container" class="app-container">
      <app-header></app-header>
      <el-main>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
      </el-main>
      <app-footer v-if="!isHome"></app-footer>
    </div>
    <div v-if="photoViewVisible">
      <photo-view
        :photoViewVisible.sync="photoViewVisible"
        :photoViewSrc="photoViewSrc"
      ></photo-view>
    </div>
  </div>
</template>

<script>
import { getSchemeTree } from '@/api/solution.js';
import { getHardwareTree, getSoftwareTree } from '@/api/product.js';
import AppFooter from '@/components/app-footer/Index.vue';
import AppHeader from '@/components/app-header/Index.vue';
import PhotoView from '@/components/photo-view/Index.vue';

export default {
  name: 'App',
  components: {
    AppFooter,
    AppHeader,
    PhotoView
  },
  data() {
    return {};
  },
  computed: {
    isHome: function () {
      return ['/', '/home'].includes(this.$route.path);
    },
    photoViewVisible: function () {
      return this.$store.state.photoViewVisible;
    },
    photoViewSrc: function () {
      return this.$store.state.photoViewSrc;
    }
  },
  mounted() {
    this.getSchemeTree();
    this.getProductionTree();
  },
  methods: {
    headerClick(item) {
      this.activeIndex = item.url;
      this.$router.push(item.url);
    },
    // 获取解决方案树
    getSchemeTree() {
      getSchemeTree().then(res => {
        let list = [];
        res.data.forEach(item => {
          let children = item.children;
          if (children && children.length) {
            list = list.concat(children);
          }
        });
        this.$store.dispatch('setSolutionTree', res.data);
        this.$store.dispatch('setSolutionList', list);
        sessionStorage.setItem('solutionTree', JSON.stringify(res.data));
        sessionStorage.setItem('solutionList', JSON.stringify(list));
      });
    },
    // 获取 产品中心 树
    getProductionTree() {
      getHardwareTree().then(res => {
        let list = res.data || [];
        this.$store.dispatch('setProductionHardwareList', list);
      });

      getSoftwareTree().then(res => {
        let list = res.data || [];
        this.$store.dispatch('setProductionSoftwareList', list);
      });
    }
  }
}
</script>

<style lang="scss">
@import "./assets/css/common.scss";
@import "./assets/css/app.scss";
</style>
