<template>
  <div id="job-list-page">
    <div class="header-img">
      <img :src="type == 0 ? society_img : school_img" alt />
      <div :class="{ left42: type == 1, text: true }">
        <div class="big">{{ type == 0 ? "加入我们" : "校园招聘" }}</div>
        <div class="min">{{ type == 0 ? "探索无限可能" : "征不平凡的你" }}</div>
      </div>
    </div>
    <div class="seach-input-content">
      <i class="el-icon-search"></i>
      <input v-model="jobTitle" type="text" placeholder="搜索职位" />
      <button @click="queryData">搜索</button>
    </div>
    <div class="job-list-content" v-loading="loading">
      <div class="select-tree">
        <div class="btn">
          <span class="seach">筛选</span>
          <!-- <span class="clear" @click="clear">清除</span> -->
        </div>
        <h2>职位类别</h2>
        <div class="job-type-tree">
          <el-tree
            ref="jobType"
            :props="props"
            node-key="code"
            :data="jobTypeData"
            show-checkbox
            @check="checkJobTypeChange"
          ></el-tree>
        </div>
        <h2>城市</h2>
        <div class="city-type">
          <el-tree
            ref="cityType"
            :props="props"
            node-key="code"
            :data="cityData"
            show-checkbox
            @check="checkCityChange"
          ></el-tree>
        </div>
      </div>
      <div class="job-list" v-if="jobList.length > 0">
        <h2>开启新的工作（{{ totalNum }}）</h2>
        <div class="list-content">
          <div
            class="job-box"
            v-for="(item, i) in jobList"
            :key="i"
            @click="showJobInfos(item)"
          >
            <h3>{{ item.title }}</h3>
            <div class="other">
              {{ item.districtName }} | {{ item.title }} |
              {{ item.type ? "校招" : "社招" }}
            </div>
            <div class="infos">{{ item.requirements }}</div>
          </div>
        </div>
        <div class="page-content">
          <el-pagination
            layout="prev, pager, next"
            :current-page="pageNum"
            @current-change="handleCurrentChange"
            :total="totalNum"
          ></el-pagination>
        </div>
      </div>
      <div class="non-data" v-else>
        <img :src="noData" alt />
      </div>
    </div>
  </div>
</template>
<script>
import { getSelectedCity, getJobTree, getJobInfoList } from '@/api/join.js';
export default {
  data() {
    return {
      society_img: require('/static/image/join-mod/job-list.png'),
      school_img: require('/static/image/join-mod/school-b.png'),
      type: 0,
      jobType: '0',

      jobTypeData: [],
      cityData: [],
      props: {
        label: 'name',
        children: 'children'
      },

      totalNum: 0,
      jobList: [],

      jobTitle: '',
      checkJobTypeList: [],
      checkCityList: [],
      pageNum: 1,

      loading: false,
      noData: require('/static/image/join-mod/no-data.png')
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.type = val.params.type;
        this.jobType = val.params.jobType || 0;
        this.getJobTree().then(() => {
          this.setJobTree(this.jobType);
        }).then(() => {
          this.getCity().then(() => {
            this.searchJob();
          });
        });
        // this.clear();
      },
      deep: true
    }
  },
  mounted() {
    this.type = this.$route.params.type;
    this.jobType = this.$route.params.jobType || 0;
    this.getJobTree().then(() => {
      this.setJobTree(this.jobType);
    }).then(() => {
      this.getCity().then(() => {
        this.searchJob();
      });
    });
  },
  methods: {
    showJobInfos(item) {
      this.$router.push(`/job-infos/${item.type}/${item.title}/${item.jobCode}/${item.districtCode}`);
    },
    clear() {
      this.$refs.jobType.setCheckedKeys([]);
      this.$refs.cityType.setCheckedKeys([]);

      this.checkJobTypeList = [];
      this.checkCityList = [];
      this.jobTitle = '';

      this.queryData();
    },
    async getJobTree() {
      await getJobTree().then(res => {
        if (this.type == 1) {
          let data = {
            code: "101",
            name: "研发",
            children: []
          };
          this.jobTypeData = [data];
        } else {
          this.jobTypeData = res.data;
        }
      });
    },
    setJobTree(code) {
      if (code != 0) {
        this.checkJobTypeList = [code];
        this.$refs.jobType.setCheckedKeys([code]);
      } else {
        console.log(this.jobTypeData);
        this.$nextTick(() => {
          if (this.type == 1) {
            this.$refs.jobType.setCheckedKeys(["101"]);
          } else {
            this.$refs.jobType.setCheckedKeys(["100"]);
          }
          this.checkJobTypeList = this.$refs.jobType.getCheckedKeys();
        });
      }
    },
    async getCity() {
      await getSelectedCity().then(res => {
        this.cityData = res.data;
        this.$nextTick(() => {
          this.$refs.cityType.setCheckedKeys(["100"]);
          // this.$refs.cityType.setCheckedNodes(this.cityData);
          this.checkCityList = this.$refs.cityType.getCheckedKeys();
        });
      });
    },
    checkJobTypeChange(obj, checkInfos) {
      if (obj.code == "100") {
        if (checkInfos.checkedKeys.includes(obj.code)) {
          this.checkJobTypeList = [obj.code];
          this.$refs.jobType.setCheckedNodes([obj]);
        } else {
          this.checkJobTypeList = [];
        }
      } else {
        let checkCode = checkInfos.checkedKeys;
        this.checkJobTypeList = checkCode;
        if (checkInfos.checkedKeys.includes("100")) {
          checkCode = checkCode.splice(1);
          this.$refs.jobType.setCheckedKeys(checkCode);
          this.checkJobTypeList = checkCode;
        }
      }
      this.queryData();
    },
    checkCityChange(obj, checkInfos) {
      if (obj.code == "100") {
        if (checkInfos.checkedKeys.includes(obj.code)) {
          this.checkCityList = [obj.code];
          this.$refs.cityType.setCheckedNodes([obj]);
        } else {
          this.checkCityList = [];
        }
      } else {
        let checkCode = checkInfos.checkedKeys;
        this.checkCityList = checkCode;
        if (checkInfos.checkedKeys.includes('100')) {
          checkCode = checkCode.splice(1);
          this.$refs.cityType.setCheckedKeys(checkCode);
          this.checkCityList = checkCode;
        }
      }
      this.queryData();
    },
    queryData() {
      this.pageNum = 1;
      this.searchJob();
    },
    searchJob(pageChange) {
      let checkList1 = [];
      let checkList2 = [];
      if (this.checkCityList.includes('100')) {
        this.cityData.map(item => {
          if (item.code != "100") {
            checkList1.push(item.code);
          }
        });
      }
      if (this.checkJobTypeList.includes('100')) {
        this.jobTypeData.map(item => {
          if (item.code != "100") {
            checkList2.push(item.code);
          }
        });
      }
      checkList1 = checkList1.length > 0 ? checkList1 : this.checkCityList;
      checkList2 = checkList2.length > 0 ? checkList2 : this.checkJobTypeList;
      console.log(checkList1, checkList2);
      let param = {
        activeStatus: 1, //招聘状态（1=启用，2=下架）
        districtCodes: checkList1, //选中城市集合
        jobCodes: checkList2, //选中职位类别集合
        pageNum: this.pageNum,
        pageSize: 10,
        title: this.jobTitle, //标题
        type: this.type //类型 0==社招 1==校招
      };
      this.loading = true;
      getJobInfoList(param)
        .then(res => {
          this.jobList = res.data;
          this.totalNum = res.totalCount;
          if (pageChange) {
            document.documentElement.scrollTop = 300;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchJob(true);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index";
</style>