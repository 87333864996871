var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-header"},[_c('el-header',{class:_vm.isHome
        ? _vm.homeActive === 1
          ? 'home-header-one'
          : 'home-header-two'
        : _vm.headerTheme == 'light'
        ? 'light-header'
        : 'dark-header'},[_c('div',{staticClass:"header-container"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.isHome
            ? _vm.homeActive === 1
              ? _vm.darkLogo
              : _vm.lightLogo
            : _vm.headerTheme == 'light'
            ? _vm.lightLogo
            : _vm.darkLogo},on:{"click":function($event){return _vm.$router.replace('/')}}}),_c('ul',{class:_vm.headerMenuClass},_vm._l((_vm.menuList),function(item,index){return _c('li',{key:index,class:{
            'menu-item': true,
            'menu-item-home': item.url === '/',
            'menu-item-short': item.url === '/solution',
            'menu-item-product': item.url === '/product-center',
            'menu-item-active': item.url == _vm.activeIndex || (item.url.indexOf(_vm.activeIndex)>-1 && _vm.activeIndex !== '/'),
          },on:{"click":function($event){return _vm.headerClick(item, index)},"mouseleave":function($event){_vm.dropVisible = false},"mouseenter":function($event){_vm.curType = item.type;
            _vm.dropVisible = true;
            _vm.dropIndex = index;}}},[_c('span',[_vm._v(" "+_vm._s(item.title)+" "),(
                item.children ||
                item.type === 'solution' ||
                item.type === 'production'
              )?_c('i',{staticClass:"el-icon-arrow-down el-icon--right"}):_vm._e()]),(item.children && _vm.dropVisible && _vm.dropIndex == index)?_c('ul',{staticClass:"drop-block"},_vm._l((item.children),function(item1,index1){return _c('li',{key:index1,class:{
                'drop-item': true,
                'drop-item-active': item1.url == _vm.activeIndex1,
              },on:{"click":function($event){return _vm.headerClick(item1)}}},[_vm._v(_vm._s(item1.title))])}),0):_vm._e(),(_vm.curType === 'solution' && _vm.dropVisible && _vm.dropIndex == index)?_c('solution-menu',{on:{"close":_vm.close}}):_vm._e(),(
              _vm.curType === 'production' && _vm.dropVisible && _vm.dropIndex == index
            )?_c('production-menu',{on:{"close":_vm.close}}):_vm._e()],1)}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }