import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Index.vue";
import OnChinaEntropy from "../views/on-china-entropy/main.vue";
import JoinUs from "../views/join-us/Index.vue";
import JobList from "../views/join-job-list/Index.vue";
import JobInfos from "../views/join-job-infos/Index.vue";
import BrandHonor from "../views/brand-honor/Index.vue";
import Solution from "../views/solution/Index.vue";
import SoftwareProduct from "../views/software-product/Index.vue";
import SoftwareProductDetail from "../views/software-product/detail/Index.vue";
import HardwareProduct from "../views/hardware-product/Index.vue";
import HardwareProductDetail from "../views/hardware-product/detail/Index.vue";
import WinterOlympics from "../views/winter-olympics/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      headerTheme: "light",
      keepAlive: false,
    },
  },
  {
    path: "/winter-olympics",
    name: "WinterOlympics",
    component: WinterOlympics,
    meta: {
      headerTheme: "dark",
      keepAlive: false,
    },
  },
  {
    path: "/on-china-entropy",
    name: "OnChinaEntropy",
    component: OnChinaEntropy,
    meta: {
      headerTheme: "dark",
      keepAlive: false,
    },
  },
  {
    path: "/join-us",
    name: "JoinUs",
    component: JoinUs,
    meta: {
      headerTheme: "dark",
      keepAlive: false,
    },
  },
  {
    path: "/job-list/:type/:jobType",
    name: "JobList",
    component: JobList,
    meta: {
      headerTheme: "dark",
      keepAlive: false,
    },
  },
  {
    path: "/job-infos/:type/:name/:jobCode/:districtCode",
    name: "JobInfos",
    component: JobInfos,
    meta: {
      headerTheme: "dark",
      keepAlive: false,
    },
  },
  {
    path: "/brand-honor",
    name: "BrandHonor",
    component: BrandHonor,
    meta: {
      headerTheme: "dark",
      keepAlive: false,
    },
  },
  {
    path: "/solution/:name",
    name: "Solution",
    component: Solution,
    meta: {
      headerTheme: "dark",
      keepAlive: false,
    },
  },
  {
    path: "/software-product",
    name: "SoftwareProduct",
    component: SoftwareProduct,
    meta: {
      headerTheme: "dark",
      keepAlive: false,
    },
  },
  {
    path: "/software-product-detail/:code",
    name: "SoftwareProductDetail",
    component: SoftwareProductDetail,
    meta: {
      headerTheme: "dark",
      keepAlive: false,
    },
  },
  {
    path: "/hardware-product",
    name: "HardwareProduct",
    component: HardwareProduct,
    meta: {
      headerTheme: "dark",
      keepAlive: true,
    },
  },
  {
    path: "/hardware-product-detail/:code/:parentTitle",
    name: "HardwareProductDetail",
    component: HardwareProductDetail,
    meta: {
      headerTheme: "dark",
      keepAlive: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
