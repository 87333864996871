<template>
  <div class="hardware-product">
    <!-- S 顶部大图 -->
    <div class="banner">
      <div class="banner-container">
        <p class="banner-content-title">硬件产品</p>
      </div>
    </div>
    <!-- E 顶部大图 -->

    <!-- S 人工智能业务平台 -->
    <div class="hardware">
      <mod-title
        class="text-mod"
        title="硬件产品"
        enTitle="HARDWARE PRODUCTS"
      />
      <div class="hardware-container">
        <ul class="hardware-tabs">
          <li
            :class="{
              'hardware-item': true,
              'hardware-item-active': hardwareActive === index,
            }"
            v-for="(item, index) in hardwareData"
            :key="index"
            @click="hardwareActive = index"
          >
            {{ item.title }}
          </li>
        </ul>
        <div class="hardware-content">
          <template v-if="hardwareData[hardwareActive].code === '01'">
            <ul class="content-list1">
              <li
                class="content-item1"
                v-for="(item, index) in hardwareData[hardwareActive].children"
                :key="index"
              >
                <p class="title">{{ item.title }}</p>
                <ul class="content-list" v-if="hardwareData[hardwareActive]">
                  <li
                    :class="{
                      'content-item': true,
                      'no-margin': index1 % 3 === 2,
                    }"
                    v-for="(item1, index1) in item.children"
                    :key="index1"
                    @click="
                      goToDetail(hardwareData[hardwareActive].title, item1)
                    "
                  >
                    <p class="title">{{ item1.title }}</p>
                    <p class="overview">{{ item1.overview }}</p>
                    <p class="img-block">
                      <img :src="item1.photo" alt="" />
                    </p>
                    <p class="more">了解更多></p>
                  </li>
                </ul>
              </li>
            </ul>
          </template>
          <template v-else>
            <ul class="content-list2" v-if="hardwareData[hardwareActive]">
              <li
                :class="{
                  'content-item': true,
                  'no-margin': index % 3 === 2,
                  'content-item-active': itemActive === index,
                }"
                v-for="(item, index) in hardwareData[hardwareActive].children"
                :key="index"
                @mouseenter="itemActive = index"
                @click="goToDetail(hardwareData[hardwareActive].title, item)"
              >
                <p class="title">{{ item.title }}</p>
                <p class="overview">{{ item.overview }}</p>
                <p class="img-block">
                  <img :src="item.photo" alt="" />
                </p>
                <p class="more">了解更多></p>
              </li>
            </ul>
          </template>
        </div>
      </div>
    </div>
    <!-- E 人工智能业务平台 -->
  </div>
</template>

<script>
import ModTitle from '@/components/mod-title/Index.vue';
import { getHardwareTree } from "@/api/product.js";
export default {
  name: "HardwareProduct",
  components: {
    ModTitle
  },
  data() {
    return {
      hardwareActive: 0,
      itemActive: 0,
      hardwareData: []
    };
  },
  mounted() {
    this.getHardwareTree();
  },
  methods: {
    getHardwareTree() {
      getHardwareTree().then(res => {
        if (res.code == 200) {
          this.hardwareData = res.data;
        } else {
          this.$message.error("获取产品数据失败");
        }
      }).catch(e => {
        console.log(e);
      });
    },
    goToDetail(title, item) {
      this.$router.push(`/hardware-product-detail/${item.code}/${title}`);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>