import AJAX from "./ajax";
import cookie from '@/utils/cookie';

/* 请求配置 start */
const API_CONFIG = {
    headers: {
        SourceUrl: 'web',
        "Content-type": "application/json"
    },
    timeout: 60*1000,
}

export default function API(opt) {
    opt.headers = Object.assign(API_CONFIG.headers || {}, opt.headers || {});
    opt.headers.Authentication = cookie.getCookie('token') || '';
    opt.timeout = opt.timeout || 60000;
    return new Promise((resolve, reject) => {
        AJAX(opt).then(res => {
            if (res.code === 200) {
                resolve(res);
            } else if (res.code === 401 || res.code === 403) {
                console.log(res);
                reject(res);
            } else {
                reject(res);
            }
        }).catch(error => {
            reject(error);
        })
    });
}