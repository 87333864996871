<template>
  <div class="honorary-certificate">
    <mod-title class="text-mod" title="相关获奖" enTitle="AWARDS" :sentenceShow="true" sentenceText="以国际物联网前瞻架构'多模异构物联网络技术'获国内外多项发明金奖" />
    <div class="list">
      <img class="move-left" src="/image/brand-honor/move-left.png" @click="move('left')" />
      <img class="move-right" src="/image/brand-honor/move-left.png" @click="move('left')" />
      <div class="item left">
        <div class="no">0{{index.left}}</div>
        <div class="blue-block"></div>
        <div class="title">{{ list[index.left - 1].title }}</div>
        <div class="content">{{ list[index.left - 1].content }}</div>
      </div>
      <div class="item center">
        <img :src="`/image/brand-honor/${list[index.center - 1].url}.png`" :class="{'img-large': index.center === 2}" />
        <div class="no">0{{index.center}}</div>
        <div class="point-deco-end deco"></div>
        <div class="main">
          <div class="title">{{ list[index.center - 1].title }}</div>
          <div class="white-block"></div>
          <div class="content" v-html="list[index.center - 1].content"></div>
        </div>
      </div>
      <div class="item right">
        <div class="no">0{{index.right}}</div>
        <div class="blue-block"></div>
        <div class="title">{{ list[index.right - 1].title }}</div>
        <div class="content">{{ list[index.right - 1].content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import modTitle from '../../../../components/mod-title/Index.vue'

export default {
  name: 'PropertyRights',
  components: {
    modTitle
  },
  data() {
    return {
      index: {
        left: 2,
        center: 1,
        right: 3
      },
      list: [
        {
          id: 1,
          title: '日内瓦国际发明奖',
          content:
            '在世界三大发明展之一的2022年日内瓦国际发明展（International Exhibition of Inventions of Geneva）上，汉熵凭借自研专利“Multimode Heterogeneous IoT Networks（多模异构IoT网络）” 斩获发明专利金奖！',
          url: '日内瓦国际发明奖_金奖'
        },
        {
          id: 2,
          title: '2021年物联网示范项目',
          content: '汉熵的“基于多模异构承载网络及边缘计算的智慧森林消防项目”被评为国家工信部物联网示范项目。',
          url: '物联网示范项目公示'
        },
        {
          id: 3,
          title: '国家发明创业奖',
          content:
            '汉熵凭借“基于多模异构承载网络及边缘计算的人工智能工业物联网技术及应用”项目，以突出的创新能力荣获“中国发明协会发明创业奖”一等奖。',
          url: '发明创业奖-创新奖_一等奖'
        }
      ]
    }
  },
  methods: {
    move(direction) {
      const { index } = this
      const { left, center, right } = index
      if ('left' === direction) {
        index.left = center
        index.center = right
        index.right = left
      } else {
        index.left = right
        index.center = left
        index.right = center
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import './Index.scss';
</style>
