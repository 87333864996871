var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hardware-product-detail"},[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"banner-container"},[_c('p',{staticClass:"banner-content-title"},[_vm._v(_vm._s(_vm.title))])])]),_c('div',{staticClass:"product-overview"},[_c('mod-title',{staticClass:"text-mod",attrs:{"title":"产品概述","enTitle":"PRODUCT OVERVIEW"}}),_c('div',{staticClass:"product-overview-container"},[_c('div',{staticClass:"img-block"},[_c('img',{attrs:{"src":_vm.photo,"alt":""}})]),_c('div',[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"parent-title"},[_c('span',[_vm._v(_vm._s(_vm.parentTitle))])]),_c('p',{staticClass:"overview"},[_vm._v(_vm._s(_vm.overview))]),(_vm.functionTitles.length === 3)?_c('ul',{staticClass:"function-list1"},_vm._l((_vm.functionTitles),function(item,index){return _c('li',{key:item,class:{
              'function-item': true,
              'function-item-active': _vm.active === index,
            },style:({
              backgroundImage: ("url('" + (_vm.images['Image' + index]) + "')"),
            }),on:{"mouseenter":function($event){_vm.active = index}}},[_c('img',{attrs:{"src":_vm.images['IconActive' + index],"alt":""}}),_c('p',{staticClass:"title"},[_vm._v(_vm._s(item))]),_c('p',{staticClass:"text",attrs:{"title":_vm.functionTexts[index]}},[_vm._v(" "+_vm._s(_vm.functionTexts[index])+" ")])])}),0):(_vm.functionTitles.length === 4)?_c('ul',{staticClass:"function-list2"},_vm._l((_vm.functionTitles),function(item,index){return _c('li',{key:item,class:{
              'function-item': true,
              'function-item-active': _vm.active === index,
            },style:({
              backgroundImage: ("url('" + (_vm.images['Image' + index]) + "')"),
            }),on:{"mouseenter":function($event){_vm.active = index}}},[_c('img',{attrs:{"src":_vm.images['IconActive' + index],"alt":""}}),_c('p',{staticClass:"title"},[_vm._v(_vm._s(item))]),_c('p',{staticClass:"text",attrs:{"title":_vm.functionTexts[index]}},[_vm._v(" "+_vm._s(_vm.functionTexts[index])+" ")])])}),0):_c('ul',{staticClass:"function-list3"},_vm._l((_vm.functionTitles),function(item,index){return _c('li',{key:item,class:{
              'function-item': true,
              'function-item-active': _vm.active === index,
            },style:({
              backgroundImage: ("url('" + (_vm.images['Image' + index]) + "')"),
            }),on:{"mouseenter":function($event){_vm.active = index}}},[_c('img',{attrs:{"src":_vm.images['IconActive' + index],"alt":""}}),_c('p',{staticClass:"title"},[_vm._v(_vm._s(item))]),_c('p',{staticClass:"text",attrs:{"title":_vm.functionTexts[index]}},[_vm._v(" "+_vm._s(_vm.functionTexts[index])+" ")])])}),0)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }