<template>
  <div class="on-china-entropy">
    <!-- S 顶部大图 -->
    <div class="banner">
      <div class="banner-container">
        <p class="banner-content-title">汉熵通信</p>
        <p class="banner-content-subtitle">下一代工业物联网缔造者</p>
        <p class="understand" @click="showVideo">
          <i class="el-icon-caret-right"></i>
          了解汉熵
        </p>
      </div>
      <div class="video-container">
        <img :src="video" />
      </div>
    </div>
    <!-- E 顶部大图 -->

    <!-- S 企业简介 -->
    <div
      class="company-profile"
      :style="{ height: isMore ? '1732px' : '1151px' }"
    >
      <mod-title class="text-mod" title="企业简介" enTitle="COMPANY PROFILE" />
      <div class="company-profile-container">
        <el-carousel
          class="company-profile-carousel"
          height="499px"
          :autoplay="false"
          arrow="never"
        >
          <el-carousel-item>
            <img
              src="../../../static/image/on-china-entropy/reception1.png"
              alt
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
              src="../../../static/image/on-china-entropy/reception2.png"
              alt
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
              src="../../../static/image/on-china-entropy/reception3.png"
              alt
            />
          </el-carousel-item>
        </el-carousel>
        <div
          class="company-profile-text"
          :style="{ height: isMore ? '1078px' : '499px' }"
        >
          <div class="content content1" v-show="!isMore">
            <p class="title">汉熵通信</p>
            <p class="text">
              汉熵通信有限公司（China Entropy Co.,
              Ltd.简称：汉熵通信）是全球首家基于自主知识产权多模异构承载网络及边缘计算的AIoT解决方案提供商和运营商。汉熵通信致力于应用先进的射频芯片（RF）、人工智能物联网（AIoT）和数据技术激活传统工业过程。汉熵通信紧密结合重点行业需求，凭借多模异构承载网络实现“智能互联”，结合以“数据湖”技术为依托的数字底座，在物理层、数据层等多层面整合，打破“行业烟囱”及“数据孤岛”，覆盖环境生态、城市治理、应急安全等众多行业领域。
            </p>
            <p class="more" @click="showMore" v-show="!isMore">了解更多>></p>
          </div>
          <div class="content content2" v-show="isMore">
            <p class="title">汉熵通信</p>
            <div class="text">
              <p>
                汉熵通信有限公司（China Entropy Co.,
                Ltd.简称：汉熵通信）是全球首家基于自主知识产权多模异构承载网络及边缘计算的AIoT解决方案提供商和运营商。
              </p>

              <p>
                汉熵通信致力于应用先进的射频芯片（RF）、人工智能物联网（AIoT）和数据技术激活传统工业过程。汉熵通信紧密结合重点行业需求，凭借多模异构承载网络实现“智能互联”，结合以“数据湖”技术为依托的数字底座，在物理层、数据层等多层面整合，打破“行业烟囱”及“数据孤岛”，覆盖环境生态、城市治理、应急安全等众多行业领域。汉熵通信以基于自主核心专利开发的双核感知通信终端及基站、多模异构承载网络、即时联动控制的边缘计算和行业AIoT算法，填补国家“卡脖子”关键技术空白。
              </p>
              <p>
                汉熵通信设计并实施了全国首个基于多模异构承载网络及边缘计算的人工智能工业物联网，为2022冬奥会提供物联网和大数据底座支撑，并以此为基础实施了智慧环保、智慧森防、智慧城市管理、智慧公安等多行业的多个项目，解决行业痛点，引领行业发展方向，打造行业标杆，保障冬奥“蓝天白云”、“绿水青山”和城市安全，践行“科技冬奥”，向全世界展示中国人的自主研发科技实力；部分项目已被列为国家工信部物联网示范项目。
              </p>
              <p>
                汉熵通信的“基于多模异构承载网络及边缘计算的人工智能工业物联网技术及应用”发明专利获得2021年度中国发明创业奖-创新奖一等奖；发明专利
                “Multimode Heterogeneous IoT
                Networks（多模异构IoT网络）”获得2022年日内瓦国际发明奖（Inventions
                of Geneva）最高奖金奖。
              </p>
              <p>
                公司创始人王铁军博士于美国哥伦比亚大学（Columbia
                University）获无线通信专业硕士学位；于美国加州大学（圣地亚哥）（University
                of California, San
                Diego）无线通信中心获博士学位。王铁军先后师从于Dr. Richard D.
                Gitlin （贝尔实验室技术总监、美国工程院院士、IEEE终身院士），Dr.
                John G.
                Proakis（数字通信的奠基者和学术领袖、IEEE终身院士），曾荣获2006年世界IEEE
                PIMRC最佳论文奖。
              </p>
              <p>
                王铁军曾于世界最大的射频芯片设计、制造公司 RF Micro Devices,
                Inc.
                （RFMD）领导技术和策略发展；曾任爱立信（Ericsson）通信有限公司东北亚区（大中国区、日本、韩国）高级副总裁兼行业与企业事业部总裁。王铁军分别入选国家高层次人才特殊支持计划、国家特聘专家、国家开发银行特聘专家、中关村高端领军人才和武汉大学客座教授、北京理工大学兼职教授及博士生导师。
              </p>
              <p>
                王铁军及团队拥有六十余项已批准授权的发明专利，其中80%的专利已授权世界500强企业有偿使用，包括eBay、三星、HTC、LG、Resideo（霍尼韦尔的物联网公司）等行业顶尖国际巨头，是知识产权发展中维权成功的范例，为世界科技进步和经济发展作出了巨大贡献。汉熵通信掌握硬核自主知识产权，已实现端到端整体解决方案的落地转化，并基于专利体系的搭建及专利维权的成功经验，利用自主标准必要专利（SEP）布局行业标准，形成保护。
              </p>
              <p>
                Dr. Gitlin、Dr. Proakis 和 Dr. David
                Lyon（蓝牙标准的奠基人、第一枚蓝牙SoC芯片设计者）这三位世界殿堂级的科学家作为技术顾问，为汉熵通信的学术和技术发展提供了强有力的支撑；公司骨干成员也多来自爱立信、高通、IBM等行业巨头，具有丰富的芯片、通信设备开发经验和人工智能、大数据研发经验，人均拥有10余年的设计、研发和实际落地经验，曾主导参与过百余项国内外大型移动通信工程、智慧化项目的设计和实施。
              </p>
              <p>
                汉熵通信一直秉承“知者不惑、仁者不忧、勇者不惧”的企业文化，汇集了一群有着爱国情怀，有着科技强国梦想的行业精英，真正“用前瞻眼光构想，用科研态度做事，用实际行动强国”，脚踏实地进行核心技术开发，在自主知识产权的道路上坚定不移地走下去，为践行国家网络强国、大数据强国战略贡献力量。
              </p>
            </div>
            <p class="fold" @click="isMore = false">收起</p>
          </div>
        </div>
        <ul class="ours-list">
          <li
            v-for="(item, index) in oursList"
            :class="{
              'ours-item': true,
            }"
            :key="index"
          >
            <span :class="item.icon"></span>
            <p class="title">{{ item.title }}</p>
            <template v-for="(item1, index1) in item.texts">
              <p class="text" :key="index1">{{ item1 }}</p>
            </template>
            <div :style="{ backgroundImage: `url(${item.bg})` }"></div>
          </li>
        </ul>
        <img
          class="transverse-point-bg"
          :style="{ top: isMore ? '1078px' : '499px' }"
          src="../../../static/image/on-china-entropy/transverse-point-bg.png"
          alt
        />
        <img
          class="portrait-point-bg"
          src="../../../static/image/on-china-entropy/portrait-point-bg.png"
          alt
        />
      </div>
    </div>
    <!-- E 企业简介 -->

    <!-- S 创始人 -->
    <div class="founder">
      <mod-title class="text-mod" title="创始人" enTitle="FOUNDER" />
      <div class="founder-container">
        <!-- <img src="../../../static/image/on-china-entropy/wang.png" alt /> -->
        <div class="founder-introduction">
          <p class="title">王铁军</p>
          <p class="education">
            美国哥伦比亚大学(Columbia University)无线通信专业硕士学位, 师从于Dr.
            Richard D. Gitlin (贝尔实验室技术总监、美国工程院院士、IEEE终身院士)
          </p>
          <p class="education education1">
            美国加州大学(圣地亚哥)(University of California, San
            Diego)无线通信中心博士学位，师从于Dr. John G. Proakis
            (数字通信的奠基者和学术领袖、IEEE终身院士)
          </p>
          <p v-html="founderText" class="introduction"></p>
        </div>
      </div>
    </div>
    <!-- E 创始人 -->

    <!-- S 联系我们 -->
    <div class="contact-us">
      <mod-title
        class="text-mod"
        title="联系我们"
        enTitle="CONTACT US"
        titleColor="#ffffff"
        enTitleColor="#c8d3ed30"
        dark="1"
      />
      <div class="contact-us-container">
        <ul class="address-list">
          <li
            v-for="(item, index) in addressList"
            :key="index"
            :class="{
              'address-item': true,
              'address-item-active': addressActive === index,
            }"
            @click="addressActive = index"
          >
            {{ item.name }}
          </li>
        </ul>
        <div class="address-content">
          <div class="left-container">
            <img :src="addressList[addressActive].url" />
            <p class="title">
              <span class="iconfont-website icon-a-weizhi1"></span>
              {{ addressList[addressActive].name }}
            </p>
            <p class="address">
              地址：{{ addressList[addressActive].address }}
            </p>
          </div>
          <div class="address-map" id="address-map">
            <img :src="addressList[addressActive].mapImg" alt srcset />
          </div>
        </div>
      </div>
    </div>
    <!-- E 联系我们 -->

    <!-- S 汉熵宣传片弹窗 -->
    <div v-if="showVideoVisible">
      <video-player
        :visible.sync="showVideoVisible"
        videoSrc="http://106.112.143.254:8333/chinaentropy-website/china-entropy.mp4"
      ></video-player>
    </div>
    <!-- E 汉熵宣传片弹窗 -->
  </div>
</template>

<script>
import ModTitle from '@/components/mod-title/Index.vue';
import VideoPlayer from '@/components/video-player/Index.vue';
import beiJingImg from '../../../static/image/on-china-entropy/beijing.png';
import shenZhenImg from '../../../static/image/on-china-entropy/shenzhen.png';
import zhanJiaKouImg from '../../../static/image/on-china-entropy/zhangjiakou.png';
import markerIcon1 from '../../../static/image/on-china-entropy/marker1.png';
import markerIcon2 from '../../../static/image/on-china-entropy/marker2.png';
import markerIcon3 from '../../../static/image/on-china-entropy/marker3.png';
import shenZhenMap from '../../../static/image/on-china-entropy/shenzhen-map.png';
import beiJingMap from '../../../static/image/on-china-entropy/beijing-map.png';
import zhanJiaKouMap from '../../../static/image/on-china-entropy/zhangjiakou-map.png';

import Culture from '../../../static/image/on-china-entropy/culture.png';
import Duty from '../../../static/image/on-china-entropy/duty.png';
import CoreValuesImage from '../../../static/image/on-china-entropy/core-values.png';
// import VideoSrc from '/static/video/china-entropy.mp4';
export default {
  name: 'OnChinaEntropy',
  components: {
    ModTitle,
    VideoPlayer
  },
  data() {
    return {
      video: require('/public/video/about.webp'),
      showVideoVisible: false,
      // videoSrc: VideoSrc,
      isMore: false,
      oursList: [
        {
          icon: 'iconfont-website icon-a-shiming1 ours-icon',
          title: '我们的使命',
          texts: ['提升网络全连接，降低行业信息熵'],
          bg: Duty
        },
        {
          icon: 'iconfont-website icon-a-qiyewenhua1 ours-icon',
          title: '我们的企业文化',
          texts: ['知者不惑，仁者不忧，勇者不惧'],
          bg: Culture
        },
        {
          icon: 'iconfont-website icon-a-hexinjiazhiguan1 ours-icon',
          title: '我们的核心价值观',
          texts: ['用前瞻眼光构想，用科研态度做事，', '用实际行动强国'],
          bg: CoreValuesImage
        }
      ],
      oursItemActive: null,
      founderText:
        '王铁军在通信领域国际最高级学术刊物上发表了多篇被业界高度关注和广泛引用的论文，荣获2006年世界IEEE PIMRC最佳论文奖。王博士数年来持续专注于前沿通信技术的研发应用，拥有六十余项已批准授权的发明专利，其中80%的专利已授权世界500强企业有偿使用，是知识产权发展中维权成功的范例，为世界科技进步和经济发展作出巨大贡献。' +
        '</br>王铁军曾于世界最大的射频芯片设计、制造公司 RF Micro Devices, Inc. (RFMD)领导技术和策略发展；曾任爱立信 （Ericsson）东北亚区（大中国区、日本、韩国）高级副总裁，兼行业与企业事业部总裁。' +
        '</br>应习总书记“大力实施网络强国战略和国家大数据战略”的号召，王铁军创办汉熵通信有限公司，践行科技兴国、实业报国。汉熵通信拥有自主知识产权，集射频芯片（RF）、人工智能物联网（AIoT）和数据技术于一体，是全球首家基于自主知识产权多模异构承载网络及边缘计算的人工智能工业物联网提供商和运营商。' +
        '</br>王铁军分别入选国家高层次人才特殊支持计划、国家特聘专家、国家开发银行特聘专家、北京市“海外高层次人才”（海聚工程）、北京市政府特聘专家、中关村高端领军人才、北京市侨联特聘专家委员会委员和武汉大学客座教授、北京理工大学兼职教授及博士生导师。' +
        '</br>王铁军作为中国致公党党员、致公党中央科技部委员、致公党北京市科技委员会副主任、政协委员积极参政议政，为国家科技创新献计献策。' +
        '</br>网球、滑雪、游泳、冲浪是王博士数十年热爱的体育活动。与自然搏击，挑战自我，正如他工作学习的态度，从不懈怠！',
      addressActive: 0,
      addressList: [
        {
          name: '北京',
          address: '北京市海淀区西三环北路甲二号院北理工国防科技园1号楼13层',
          lat: 39.960961,
          lng: 116.31076,
          url: beiJingImg,
          markerIcon: markerIcon1,
          mapImg: beiJingMap
        },
        {
          name: '深圳',
          address: '深圳市南山区深圳湾科技生态园9栋B1座2206',
          lat: 22.531829,
          lng: 113.954123,
          url: shenZhenImg,
          markerIcon: markerIcon2,
          mapImg: shenZhenMap
        },
        {
          name: '张家口',
          address: '河北省张家口经开区凤凰城2期27号楼4层',
          lat: 40.765825,
          lng: 114.879994,
          url: zhanJiaKouImg,
          markerIcon: markerIcon3,
          mapImg: zhanJiaKouMap
        }
      ],
      map: null
    };
  },
  mounted() {
    // this.initMap();
  },
  watch: {
    addressActive: {
      handler(val) {
        this.showMarker(this.addressList[val]);
      },
      immediate: false
    }
  },
  methods: {
    // 初始化地图
    initMap() {
      this.map = new this.$AMap.Map('address-map', {
        zoom: 12, // 缩放等级
        zooms: [10, 15],
        center: [115.131652, 40.991302], //中心点坐标
        mapStyle: 'amap://styles/e4739f2a3637b23a098154fb920b341e',
        dragEnable: false,
        zoomEnable: false
      });
      this.showMarker(this.addressList[this.addressActive]);
    },
    showMarker(point) {
      console.log(point);
      this.map.clearMap();
      const marker = new this.$AMap.Marker({
        icon: point.markerIcon,
        position: new this.$AMap.LngLat(point.lng, point.lat),
        offset: new this.$AMap.Pixel(-57, -51)
      });
      this.map.add(marker);
      this.map.setCenter([point.lng, point.lat]);
    },
    //公司简介展开
    showMore() {
      this.isMore = true;
    },
    // 播放公司宣传片
    showVideo() {
      this.showVideoVisible = true;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./main.scss";
</style>
