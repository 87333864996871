<template>
  <div class="patent-certificate">
    <mod-title class="text-mod" title="专利证书" enTitle="PATENT CERTIFICATE" sentenceText="被多家世界500强企业有偿使用的专利证明了汉熵研发的前瞻性和经济价值" />
    <div class="patent-certificate-main">
      <div class="patent-left">
        <img :src="`/image/brand-honor/patent/专利证书${active}.png`" />
      </div>
      <div class="patent-right">
        <div class="patent-right-top">
          <img
            v-for="item in list.filter(({id})=>id<3)"
            :key="item.id"
            :class="`img${item.id}${active === item.id?' img-active':''}`"
            :src="`/image/brand-honor/patent/专利证书${item.id}-1.png`"
            @click="active = item.id"
          />
        </div>
        <div class="patent-right-bottom">
          <img
            v-for="item in list.filter(({id})=>id>2)"
            :key="item.id"
            :class="`img${item.id}${active === item.id?' img-active':''}`"
            :src="`/image/brand-honor/patent/专利证书${item.id}-1.png`"
            @click="active = item.id"
          />
        </div>
      </div>
    </div>
    <!--        <div class="patent-certificate-name">-->
    <!--            <span>{{ activeName }}</span>-->
    <!--        </div>-->
  </div>
</template>

<script>
import modTitle from '../../../../components/mod-title/Index.vue'

export default {
  name: 'CoreTechnology',
  components: {
    modTitle
  },
  data() {
    return {
      active: 1,
      list: [
        {
          id: 1,
          name: '汉熵北京专利墙'
        },
        {
          id: 2,
          name: 222
        },
        {
          id: 3,
          name: 111
        },
        {
          id: 4,
          name: 111
        },
        {
          id: 5,
          name: 111
        }
      ]
    }
  },
  computed: {
    activeName() {
      return this.list.find(({ id }) => id === this.active).name
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
@import './Index.scss';
</style>
