<template>
  <div class="welfare-content">
    <div class="list" v-for="(name,i) in list" :key="i" @mouseenter="sel(i)">
      <div :class="{'left-content':true
      ,'blue-one':i === activeOne}">
        <h2>{{name}}</h2>
        <ul>
          <li v-for="(name,k) in listInfos[i]" :key="k">
            <span :class="{'blue-bg':i===activeOne}"></span>
            <div :class="{'blue-text-color':i!==activeOne}">{{name.text}}</div>
          </li>
        </ul>
      </div>
      <div class="bg-img">
        <img :src="bgImgList[i]" alt />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'welfare',
  data() {
    return {
      list: ['健康福利', '贴心服务', '长期激励', '汉熵专享'],
      bgImgList: [
        require('/static/image/join-mod/fl/2.png'),
        require('/static/image/join-mod/fl/3.png'),
        require('/static/image/join-mod/fl/4.png'),
        require('/static/image/join-mod/fl/1.png')
      ],
      listInfos: [
        [{ text: '年度体检：用心工作，健康生活' }, { text: '医疗双保险：基础+补充，就医更省心' }, { text: '子女医疗：宝宝健康，家长安心' }],
        [
          { text: '高档办公区域：市中心高档园区，安静优雅办公环境' },
          { text: '就近补贴：就近租房补贴，帮你实现生活和工作的便捷' },
          { text: '企业滴滴：因公出行企业付，发票报销快捷方便' },
          { text: '节日礼包：节日礼物时时有，这里是家园，我们同欢乐' }
        ],
        [
          {
            text: '汉熵通信是真正以人为本的公司，诚心邀请有志于创业的伙伴加盟；只要你有志气有能力，我们一起加入持股平台，一起挥洒激情，打造物联网科技帝国，一起创富不是梦'
          }
        ],
        [
          { text: '国家级专家言传身教，技术大牛身边有，天天涨IQ' },
          { text: '名校联合实验室，教授提供学术指导' },
          { text: 'Leader身边坐，有话随时说' },
          { text: '汉熵大讲堂，天天涨知识' }
        ]
      ],

      activeOne: 0
    }
  },
  methods: {
    sel(k) {
      this.activeOne = k
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index';
</style>
