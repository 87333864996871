var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hardware-product"},[_vm._m(0),_c('div',{staticClass:"hardware"},[_c('mod-title',{staticClass:"text-mod",attrs:{"title":"硬件产品","enTitle":"HARDWARE PRODUCTS"}}),_c('div',{staticClass:"hardware-container"},[_c('ul',{staticClass:"hardware-tabs"},_vm._l((_vm.hardwareData),function(item,index){return _c('li',{key:index,class:{
            'hardware-item': true,
            'hardware-item-active': _vm.hardwareActive === index,
          },on:{"click":function($event){_vm.hardwareActive = index}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0),_c('div',{staticClass:"hardware-content"},[(_vm.hardwareData[_vm.hardwareActive].code === '01')?[_c('ul',{staticClass:"content-list1"},_vm._l((_vm.hardwareData[_vm.hardwareActive].children),function(item,index){return _c('li',{key:index,staticClass:"content-item1"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),(_vm.hardwareData[_vm.hardwareActive])?_c('ul',{staticClass:"content-list"},_vm._l((item.children),function(item1,index1){return _c('li',{key:index1,class:{
                    'content-item': true,
                    'no-margin': index1 % 3 === 2,
                  },on:{"click":function($event){return _vm.goToDetail(_vm.hardwareData[_vm.hardwareActive].title, item1)}}},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item1.title))]),_c('p',{staticClass:"overview"},[_vm._v(_vm._s(item1.overview))]),_c('p',{staticClass:"img-block"},[_c('img',{attrs:{"src":item1.photo,"alt":""}})]),_c('p',{staticClass:"more"},[_vm._v("了解更多>")])])}),0):_vm._e()])}),0)]:[(_vm.hardwareData[_vm.hardwareActive])?_c('ul',{staticClass:"content-list2"},_vm._l((_vm.hardwareData[_vm.hardwareActive].children),function(item,index){return _c('li',{key:index,class:{
                'content-item': true,
                'no-margin': index % 3 === 2,
                'content-item-active': _vm.itemActive === index,
              },on:{"mouseenter":function($event){_vm.itemActive = index},"click":function($event){return _vm.goToDetail(_vm.hardwareData[_vm.hardwareActive].title, item)}}},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"overview"},[_vm._v(_vm._s(item.overview))]),_c('p',{staticClass:"img-block"},[_c('img',{attrs:{"src":item.photo,"alt":""}})]),_c('p',{staticClass:"more"},[_vm._v("了解更多>")])])}),0):_vm._e()]],2)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"banner-container"},[_c('p',{staticClass:"banner-content-title"},[_vm._v("硬件产品")])])])}]

export { render, staticRenderFns }