<template>
  <div class="software-product-detail">
    <!-- S 顶部大图 -->
    <div class="banner">
      <div class="banner-container">
        <p class="banner-content-title">{{ title }}</p>
      </div>
    </div>
    <!-- E 顶部大图 -->

    <!-- S 产品概述 -->
    <div class="product-overview">
      <mod-title class="text-mod" title="产品概述" enTitle="PRODUCT OVERVIEW" />
      <div class="product-overview-container">
        <p>{{ overview }}</p>
        <ul class="product-overview-list1" v-if="functionTitles.length == 3">
          <li
            :style="{
              backgroundImage: `url('${images['Image3' + index]}')`,
            }"
            :class="{
              'product-overview-item': true,
              'product-overview-item-first': index === 0,
              'product-overview-item-second': index === 1,
              'product-overview-item-thrid': index === 2,
              'product-overview-item-active': productOverviewActive === index,
            }"
            v-for="(item, index) in functionTitles"
            :key="index"
            @mouseenter="productOverviewActive = index"
          >
            <img
              :src="
                productOverviewActive === index
                  ? images['IconActive' + index]
                  : images['Icon' + index]
              "
              alt
            />
            <!-- <p class="icon">
                <span class="iconfont icon-shipin"></span>
            </p>-->
            <p class="title">{{ item }}</p>
            <p class="text">{{ functionTexts[index] }}</p>
          </li>
        </ul>
        <ul
          class="product-overview-list3"
          v-else-if="functionTitles.length == 5"
        >
          <li
            :style="{backgroundImage: `url(${images['Image5' + index + (productOverviewActive === index ? 'Active':'')]})`}"
            :class="{
              'product-overview-item': true,
              'product-overview-item-first': index === 0,
              'product-overview-item-second': index === 1,
              'product-overview-item-thrid': index === 2,
              'product-overview-item-fourth': index === 3,
              'product-overview-item-fifth': index === 4,
              'product-overview-item-active': productOverviewActive === index,
            }"
            v-for="(item, index) in functionTitles"
            :key="index"
            @mouseenter="productOverviewActive = index"
          >
            <img
              :src="
                productOverviewActive === index
                  ? images['IconActive' + index]
                  : images['Icon' + index]
              "
              alt
            />
            <!-- <p class="icon">
                <span class="iconfont icon-shipin"></span>
            </p>-->
            <p class="title">{{ item }}</p>
            <p class="text">{{ functionTexts[index] }}</p>
          </li>
        </ul>
        <ul class="product-overview-list2" v-else>
          <li
            :style="{
              backgroundImage: `url('${images['Image4' + index]}')`,
            }"
            :class="{
              'product-overview-item': true,
              'product-overview-item-active': productOverviewActive === index,
            }"
            v-for="(item, index) in functionTitles"
            :key="index"
            @mouseenter="productOverviewActive = index"
          >
            <img
              :src="
                productOverviewActive === index
                  ? images['IconActive' + index]
                  : images['Icon' + index]
              "
              alt
            />
            <!-- <p class="icon">
                <span class="iconfont icon-shipin"></span>
            </p>-->
            <p class="title">{{ item }}</p>
            <p class="text">{{ functionTexts[index] }}</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- E 产品概述 -->

    <!-- S 方案优势 -->
    <div class="program-advantages">
      <mod-title
        class="text-mod"
        title="产品优势"
        enTitle="PROGRAM ADVANTAGES"
        titleColor="#ffffff"
        enTitleColor="#c8d3ed30"
        dark="1"
      />
      <div class="program-advantages-container">
        <ul class="program-advantages-list">
          <li
            v-for="(item, index) in advantageTitles"
            :class="{
              'program-advantages-item': true,
              'program-advantages-item-active':
                programDdvantagesActive === index,
            }"
            :style="{
              backgroundImage: `url('${
                programDdvantagesActive === index
                  ? images['Image2' + index + 'Active']
                  : images['Image2' + index]
              }')`,
            }"
            :key="index"
            @mouseenter="programDdvantagesActive = index"
          >
            <p class="title">{{ item }}</p>
            <p class="text">{{ advantageTexts[index] }}</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- E 方案优势 -->
  </div>
</template>

<script>
import ModTitle from '@/components/mod-title/Index.vue';
import Image40 from '../../../../static/image/product/software-detail/4-0.png';
import Image41 from '../../../../static/image/product/software-detail/4-1.png';
import Image42 from '../../../../static/image/product/software-detail/4-2.png';
import Image43 from '../../../../static/image/product/software-detail/4-3.png';

import Image30 from '../../../../static/image/product/software-detail/3-0.png';
import Image31 from '../../../../static/image/product/software-detail/3-1.png';
import Image32 from '../../../../static/image/product/software-detail/3-2.png';

import Image50 from '../../../../static/image/product/software-detail/5-0.png';
import Image51 from '../../../../static/image/product/software-detail/5-1.png';
import Image52 from '../../../../static/image/product/software-detail/5-2.png';
import Image53 from '../../../../static/image/product/software-detail/5-3.png';
import Image54 from '../../../../static/image/product/software-detail/5-4.png';
import Image50Active from '../../../../static/image/product/software-detail/5-0-active.png';
import Image51Active from '../../../../static/image/product/software-detail/5-1-active.png';
import Image52Active from '../../../../static/image/product/software-detail/5-2-active.png';
import Image53Active from '../../../../static/image/product/software-detail/5-3-active.png';
import Image54Active from '../../../../static/image/product/software-detail/5-4-active.png';

import Image20 from '../../../../static/image/product/software-detail/2-0.png';
import Image20Active from '../../../../static/image/product/software-detail/2-0-active.png';
import Image21 from '../../../../static/image/product/software-detail/2-1.png';
import Image21Active from '../../../../static/image/product/software-detail/2-1-active.png';
import Image22 from '../../../../static/image/product/software-detail/2-2.png';
import Image22Active from '../../../../static/image/product/software-detail/2-2-active.png';
import Image23 from '../../../../static/image/product/software-detail/2-3.png';
import Image23Active from '../../../../static/image/product/software-detail/2-3-active.png';

import Icon0 from '../../../../static/image/product/hardware-detail/icon0.png';
import Icon1 from '../../../../static/image/product/hardware-detail/icon1.png';
import Icon2 from '../../../../static/image/product/hardware-detail/icon2.png';
import Icon3 from '../../../../static/image/product/hardware-detail/icon3.png';
import Icon4 from '../../../../static/image/product/hardware-detail/icon4.png';
import IconActive0 from '../../../../static/image/product/hardware-detail/icon0-active.png';
import IconActive1 from '../../../../static/image/product/hardware-detail/icon1-active.png';
import IconActive2 from '../../../../static/image/product/hardware-detail/icon2-active.png';
import IconActive3 from '../../../../static/image/product/hardware-detail/icon3-active.png';
import IconActive4 from '../../../../static/image/product/hardware-detail/icon4-active.png';
import { getSoftwareDetail } from '@/api/product.js';
export default {
  name: 'SoftwareProductDetail',
  components: {
    ModTitle
  },
  data() {
    return {
      images: {
        Image40,
        Image41,
        Image42,
        Image43,
        Image30,
        Image31,
        Image32,
        Image50,
        Image51,
        Image52,
        Image53,
        Image54,
        Image50Active,
        Image51Active,
        Image52Active,
        Image53Active,
        Image54Active,
        Image20,
        Image20Active,
        Image21,
        Image21Active,
        Image22,
        Image22Active,
        Image23,
        Image23Active,
        Icon0,
        Icon1,
        Icon2,
        Icon3,
        Icon4,
        IconActive0,
        IconActive1,
        IconActive2,
        IconActive3,
        IconActive4
      },
      overview: null,
      functionTitles: [],
      functionTexts: [],
      advantageTitles: [],
      advantageTexts: [],
      productOverviewActive: 0,
      programDdvantagesActive: 0,
      title: null
    };
  },
  watch: {
    $route: {
      handler(val) {
        let code = val.params.code;
        this.getSoftwareDetail(code);
        this.productOverviewActive = 0;
        this.programDdvantagesActive = 0;
      },
      immediate: true
    }
  },

  mounted() {
    // let code = this.$route.params.code
    // this.getSoftwareDetail(code)
  },
  methods: {
    getSoftwareDetail(code) {
      getSoftwareDetail({ code: code })
        .then(res => {
          if (res.code == 200) {
            let data = res.data[0];
            this.title = data.title;
            this.overview = data.overview;
            this.functionTitles = [];
            this.functionTexts = [];
            this.advantageTitles = [];
            this.advantageTexts = [];
            data.function.split('\r\n').map((item, index) => {
              if (index % 2 === 0) {
                this.functionTitles.push(item);
              } else {
                this.functionTexts.push(item);
              }
            });
            data.advantage.split('\r\n').map((item, index) => {
              if (index % 2 === 0) {
                this.advantageTitles.push(item);
              } else {
                this.advantageTexts.push(item);
              }
            });
          }
        })
        .catch(err => {
          console.log(err);
          this.title = '获取产品数据失败';
          this.$message.error('获取产品数据失败');
        });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
