<template>
    <div class="app-footer">
        <div class="footer-container">
            <div class="footer-content">
                <div class="footer-content-left">
                    <img src="../../../static/image/logo.png" alt=""/>
                    <p class="phone">
                        <span class="iconfont-website icon-a-dianhua1"></span>
                        010-8858-0888
                    </p>
                    <p class="address">北京：北京市海淀区西三环北路甲二号院北理工国防科技园1号楼13层</p>
                    <p class="address">深圳：深圳市南山区深圳湾科技生态园9栋B1座2206</p>
                    <p class="address">张家口：河北省张家口经开区凤凰城2期27号楼4层</p>
                    <p class="email">
                        <span class="iconfont-website icon-a-youxiang1"></span>
                        marketing@chinaentropy.com
                    </p>
                </div>
                <div class="footer-content-right">
                    <div v-for="(item1, index1) in list" :key="index1" class="list1">
                        <p class="list-item1">{{ item1.name }}</p>
                        <div
                                v-for="(item2, index2) in item1.childList"
                                :key="index2"
                                class="list2"
                                @click="jump(item2)"
                        >
                            <p class="list-item2">{{ item2.name }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <p class="bottom-text">
                Copyright ® 2022 Designed and Developed by China Entropy Co., Ltd. All
                Rights Reserved.
            </p>
        </div>
    </div>
</template>
<script>
  export default {
    name: "AppFooter",
    data() {
      return {
        list: [
          {
            name: "关于汉熵",
            childList: [
              {
                name: "公司简介",
                url: '/on-china-entropy'
              },
              {
                name: "品牌荣誉",
                url: '/brand-honor'
              },
              {
                name: "加入我们",
                url: '/join-us'
              },
            ]
          },
          {
            name: "解决方案",
            childList: [
              {
                name: "智慧林草",
                key: '智慧林草',
                url: '/solution'
              },
              {
                name: "碳中和",
                key: '碳中和',
                url: '/solution'
              },
              {
                name: "环境保护",
                key: '智慧环保',
                url: '/solution'
              },
              {
                name: "城管市政",
                key: '智慧城管',
                url: '/solution'
              },
              {
                name: "公共安全",
                key: '智慧公安',
                url: '/solution'
              },
              {
                name: "水利水务",
                key: '智慧水利',
                url: '/solution'
              },
              {
                name: "工地建筑",
                key: '智慧工地',
                url: '/solution'
              },
              {
                name: "应急管理",
                key: '智慧应急',
                url: '/solution'
              },
              {
                name: "校园管理",
                key: '智慧校园',
                url: '/solution'
              }
            ]
          },
          {
            name: "产品中心",
            childList: [
              {
                name: "软件产品",
                url: '/software-product'
              },
              {
                name: "硬件产品",
                url: '/hardware-product'
              },
            ]
          },
          {
            name: "加入我们",
            childList: [
              {
                name: "社会招聘",
                url: '/job-list/0/0'
              },
              {
                name: "校园招聘",
                url: '/job-list/1/0'
              },
            ]
          }
        ]
      };
    },
    methods: {
      jump(item) {
        if (item.url.indexOf('/job-list') > -1) {
          window.open(item.url, '_blank');
        } else {
          if (item.url.indexOf('/solution') > -1) {
            this.$router.push(`/solution/${item.key}解决方案`)
          } else {
            this.$router.push(item.url)
          }
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
    .app-footer {
        position: relative;
        width: 100%;
        height: 494px;
        display: flex;
        justify-content: center;
        color: #ffffff;
        background-color: #040a14;
        border-top: 3px solid rgba($color: #f2f3f5, $alpha: 0.1);

        & > .footer-container {
            position: relative;
            margin-top: 60px;
            width: 1280px;

            & > .footer-content {
                display: flex;
                justify-content: space-between;

                & > .footer-content-left {
                    & > .phone {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 40px 0;
                        width: 180px;
                        height: 40px;
                        background-color: #165dff;

                        & > span {
                            margin-right: 10px;
                            font-size: 28px;
                        }

                        &:hover {
                            opacity: 0.8;
                        }
                    }

                    & > .address {
                        cursor: pointer;
                        line-height: 30px;
                        opacity: 0.8;

                        &:hover {
                            opacity: 1;
                        }
                    }

                    & > .email {
                        cursor: pointer;
                        margin-top: 50px;
                        opacity: 0.8;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                & > .footer-content-right {
                    display: flex;

                    & > .list1 {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        & > .list-item1 {
                            cursor: pointer;
                            line-height: 50px;
                            margin-left: 80px;
                        }

                        & > .list2 {
                            & > .list-item2 {
                                cursor: pointer;
                                margin-left: 80px;
                                line-height: 40px;
                                font-size: 14px;
                                opacity: 0.8;

                                &:hover {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }

            & > .bottom-text {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                text-align: center;
                line-height: 30px;
                opacity: 0.8;
                font-size: 14px;
            }
        }
    }
</style>
