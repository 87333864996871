<template>
  <div class="solution">
    <div class="solution-header">
      <div class="title">{{ solution.title }}</div>
    </div>
    <div class="solution-desc">
      <mod-title class="text-mod" title="方案概述" enTitle="SOLUTION OVERVIEW" />
      {{ solution.desc }}
    </div>
    <!--  整体解决方案  -->
    <whole v-if="solution.type === 2" :solution="solution"></whole>
    <!--  产品解决方案  -->
    <product v-else :solution="solution"></product>
  </div>
</template>

<script>
import modTitle from '../../components/mod-title/Index.vue'
import whole from './components/whole/Index'
import product from './components/product/Index'

export default {
  name: 'Solution',
  components: {
    modTitle,
    whole,
    product
  },
  data() {
    return {
      solution: {
        title: ''
      }
    }
  },
  computed: {
    solutionList() {
      return JSON.parse(sessionStorage.getItem('solutionList'))
      // return this.$store.getters.solutionList
    }
  },
  watch: {
    '$route.params.name'() {
      this.init()
    }
  },
  methods: {
    init() {
      const solutionName = this.$route.params.name
      this.solution = this.solutionList.find(({ title }) => title === solutionName) //type:1产品 2整体
      let desc = this.solution.overview.split('\r\n')
      this.solution.desc = desc[0]
      this.solution.descList = []
      desc.map((item, index) => {
        if (index > 0) {
          this.solution.descList.push(item)
        }
      })
      console.log('方案:', solutionName, this.solution)
    }
  },
  created() {
    this.init()
  },
  mounted() {}
}
</script>
<style lang="scss" scoped>
@import './Index.scss';
</style>
