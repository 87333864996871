<template>
  <div class="plan-advantage">
    <mod-title class="text-mod" title="方案优势" enTitle="SOLUTION ADVANTAGES" titleColor="#ffffff" enTitleColor="#98A2BA" dark="1" />
    <div class="list">
      <div
        v-for="(item, index) in advantageTitles"
        :key="index"
        :class="`item ${active === index?'active':''}`"
        :style="{backgroundImage: `url(/image/solution/solution-advantage-${index+1}${active === index?'-active':''}.png)`}"
        @mouseenter="onMouseEnter(index)"
      >
        <div class="title">{{item}}</div>
        <div class="content">{{ advantageTexts[index] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import modTitle from '../../../../../../components/mod-title/Index.vue'

export default {
  name: 'PlanAdvantage',
  components: {
    modTitle
  },
  props: {
    solution: {}
  },
  watch: {
    solution() {
      this.getAdvantage()
    }
  },
  data() {
    return {
      active: 0,
      advantageTitles: [],
      advantageTexts: []
    }
  },
  mounted() {
    this.getAdvantage()
  },
  methods: {
    onMouseEnter(id) {
      this.active = id
    },
    getAdvantage() {
      this.advantageTitles = []
      this.advantageTexts = []
      this.solution.advantage.split('\r\n').map((item, index) => {
        if (index % 2 === 0) {
          this.advantageTitles.push(item)
        } else {
          this.advantageTexts.push(item)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import './Index.scss';
</style>
