<template>
  <div class="recruitment-jop">
    <div
      class="list-card"
      v-for="(item, i) in typeList"
      :key="i"
      @click="job(item)"
    >
      <el-card class="card-ref" :body-style="{ padding: '0px' }">
        <img :src="bgImgList[i]" class="image" />
        <div class="card-text">
          <span>{{ item.name }}</span>
          <el-button type="text" class="button"
            >共{{ item.count }}个职位 ></el-button
          >
        </div>
        <div class="mark">
          <div class="title">{{ item.name }}</div>
          <div class="infos">共{{ item.count }}个职位 ></div>
        </div>
      </el-card>
    </div>
    <div class="more-job" @click="job(null)">
      更多职位
      <i class="el-icon-right"></i>
    </div>
  </div>
</template>
<script>
import { getJobCountGroup } from '@/api/join.js';
export default {
  name: 'recruitment',
  props: {
    showType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      bgImgList: [
        require('/static/image/join-mod/job/1.png'),
        require('/static/image/join-mod/job/2.png'),
        require('/static/image/join-mod/job/3.png'),
        require('/static/image/join-mod/job/4.png'),
        require('/static/image/join-mod/job/5.png'),
        require('/static/image/join-mod/job/6.png')
      ],

      typeList: [],

      society: [],
      school: []
    };
  },
  watch: {
    showType(k) {
      k === 0 ? (this.typeList = this.society) : (this.typeList = this.school);
    }
  },
  mounted() {
    this.getJobAndNum();
  },
  methods: {
    job(item) {
      let val = item || { type: this.showType, code: 0 };
      const {
        href
      } = this.$router.resolve({
        path: `/job-list/${val.type}/${val.code}`
      });
      window.open(href, '_blank');
      // this.$router.push(`/job-list/${val.type}/${val.code}`)
    },
    getJobAndNum() {
      getJobCountGroup().then(res => {
        let typeData = res.data;
        for (let i = 0; i < typeData.length; i++) {
          const el = typeData[i];
          if (el.type === 0) {
            this.society = el.value;
          } else if (el.type === 1) {
            this.school = el.value;
          }
        }

        this.typeList = this.society;
      });
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index";
</style>