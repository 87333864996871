import API from "./index.js";
const Api = "/api";

// 获取软件产品集合-树结构
export function getSoftwareTree(params) {
  return API({
    url: Api + "/productCenter/getSoftware/Tree",
    method: "GET",
    params,
  });
}
// 获取软件产品详情
export function getSoftwareDetail(params) {
  return API({
    url: Api + "/productCenter/getSoftware/ListByCode",
    method: "GET",
    params,
  });
}

//获取硬件产品集合-树结构
export function getHardwareTree(params) {
  return API({
    url: Api + "/productCenter/getHardware/Tree",
    method: "GET",
    params,
  });
}

// 获取硬件产品详情
export function getHardwareDetail(params) {
  return API({
    url: Api + "/productCenter/getHardware/ListByCode",
    method: "GET",
    params,
  });
}
