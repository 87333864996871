<template>
    <div ref="bh" class="brand-honor" :style="{zIndex: isFixed?1100:1}">
        <div class="brand-honor-header">
            <div class="title">
                品牌荣誉
            </div>
            <div class="subTitle">
                专业汇聚价值，价值成就品牌
            </div>
        </div>
        <div ref="tabs" :class="['brand-honor-tabs',isFixed?'brand-honor-tabs-fixed':'']">
            <div v-for="tab in tabList" :key="tab.id" :class="`tab${activeTab === tab.id ? ' active':''}`"
                 @click="changeTab(tab)">
                {{ tab.name }}
            </div>
        </div>
        <honorary-certificate ref="hc"></honorary-certificate>
        <standard-lead ref="sl"></standard-lead>
<!--        <property-rights ref="pr"></property-rights>-->
        <patent-certificate ref="pc"></patent-certificate>
        <core-technology ref="ct"></core-technology>
        <Cooperation ref="co"></Cooperation>
        <enterprise-qualification ref="eq"></enterprise-qualification>
        <div v-if="showBack" class="back-to-top" @click="backToTop">
            <i class="iconfont-website icon-backtop"></i>
        </div>
    </div>
</template>

<script>
  import HonoraryCertificate from './components/honorary-certificate/Index.vue'
  // import PropertyRights from './components/property-rights/Index.vue'
  import StandardLead from './components/standard-lead/Index.vue'
  import Cooperation from './components/cooperation/Index.vue'
  import EnterpriseQualification from './components/enterprise-qualification/Index.vue'
  import CoreTechnology from './components/core-technology/Index.vue'
  import PatentCertificate from './components/patent-certificate/Index.vue'


  export default {
    name: 'BrandHonor',
    components: {
      HonoraryCertificate,
      // PropertyRights,
      StandardLead,
      Cooperation,
      EnterpriseQualification,
      CoreTechnology,
      PatentCertificate
    },
    data() {
      return {
        isFixed: false,
        showBack:false,
        activeTab: 1,
        tabList: [
          {
            id: 1,
            name: '相关获奖',
            top: 400,
          },
          // {
          //   id: 1,
          //   name: '自主知识产权',
          //   top: 400,
          // },
          {
            id: 2,
            name: '标准引领',
            top: 1200,
          }, {
            id: 3,
            name: '专利证书',
            top: 1900,
          }, {
            id: 4,
            name: '核心技术',
            top: 2640,
          }, {
            id: 5,
            name: '产学研合作',
            top: 3400,
          },{
            id: 6,
            name: '企业资质',
            top: 4320,
          }
        ]
      }
    },
    methods: {
      getScroll() {
        // let scrollTop = e.target.scrollTop;
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        // console.log(scrollTop)
        this.isFixed = scrollTop > 396
        this.showBack = scrollTop > 500
        if (scrollTop < this.tabList[0].top) {
          this.activeTab = 1
        } else if (scrollTop >= this.tabList[5].top) {
          this.activeTab = 6
        } else {
          for (let i = 0; i < 5; i++) {
            if (scrollTop >= this.tabList[i].top && scrollTop < this.tabList[i + 1].top) {
              this.activeTab = i + 1
              return
            }
          }
        }
      },
      changeTab(tab) {
        this.activeTab = tab.id
        window.scrollTo({left: 0, top: this.tabList.find(({id}) => id === tab.id).top, behavior: 'smooth'})
      },
      backToTop(){
        window.scrollTo({left: 0,top:  0, behavior: 'smooth'})
      }
    },
    mounted() {
      window.addEventListener('scroll', this.getScroll, true);
    },
    destroyed() {
      window.removeEventListener('scroll', this.getScroll);
    },
  }
</script>
<style lang="scss" scoped>
    @import "./Index.scss";
</style>
