<template>
  <div class="standard-lead">
    <mod-title
      class="text-mod"
      title="标准引领"
      enTitle="LEADING STANDARD"
      titleColor="#ffffff"
      enTitleColor="#c8d3ed30"
      dark="1"
      sentenceText="以自主知识产权参与行业标准制定，助力中国的国际竞争"
    />
    <div class="list">
      <div v-for="item in list" :key="item.id" :class="`item ${active === item.id?'active':''}`" @mouseenter="onMouseEnter(item.id)">
        <div class="name">{{item.name}}</div>
        <div class="title">{{item.title}}</div>
        <div class="title">{{item.subtitle}}</div>
        <div class="content">{{item.content}}</div>
        <div class="color-block"></div>
        <div class="standard">{{item.standard}}</div>
        <img v-if="active !== item.id" :src="require(`../../../../assets/img/brand-honor/standard-lead-item-${item.id}.png`)" />
      </div>
    </div>
  </div>
</template>

<script>
import modTitle from '../../../../components/mod-title/Index.vue'

export default {
  name: 'PropertyRights',
  components: {
    modTitle
  },
  data() {
    return {
      active: 1,
      list: [
        {
          id: 1,
          name: '中华人民共和国交通运输行业标准',
          title: '营运车辆全景环视系统技术',
          subtitle: '要求和试验方法',
          content: 'Commercial vehicle around view monitor system technical requirements and test procedurs',
          standard: 'JT/T 2020-06'
        },
        {
          id: 2,
          name: '河北省地方标准',
          title: '污染物在线自动监控（监测）',
          subtitle: '物联网关侧技术算法',
          content: 'The technology and algorithm of Internet of Things gateway for online automatic monitoring systems of pollutant',
          standard: 'DB13'
        },
        {
          id: 3,
          name: '河北省地方标准',
          title: '污染物在线自动监控（监测）',
          subtitle: '终端侧技术算法',
          content: 'The technology and algorithm of Internet of Things terminal for online automatic monitoring systems of pollutant',
          standard: 'DB13'
        }
      ]
    }
  },
  methods: {
    onMouseEnter(id) {
      this.active = id
    }
  }
}
</script>
<style lang="scss" scoped>
@import './Index.scss';
</style>
