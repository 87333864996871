<template>
  <div class="related-products">
    <mod-title class="text-mod" title="应用产品" enTitle="APPLICATION & PRODUCTS" />
    <div class="product-types">
      <div v-for="type in types" :key="type.id" :class="`type ${curType === type.id?'active':''}`" @click="changeType(type.id)">
        <span>{{ type.name }}</span>
      </div>
    </div>
    <div class="list">
      <div
        v-for="item in showList"
        :key="item.code"
        :class="`item ${active === item.code?'active':''}`"
        @mouseenter="onMouseEnter(item.code)"
        @mouseleave="active = -1"
        @click="toProductDetail(item)"
      >
        <div class="title">{{item.title}}</div>
        <div v-if="active === item.code" class="href">了解更多&nbsp;></div>
      </div>
    </div>
  </div>
</template>
<script>
import { getProductByCode } from '@/api/solution.js'
import modTitle from '../../../../../../components/mod-title/Index.vue'

export default {
  name: 'RelatedProducts',
  components: {
    modTitle
  },
  props: {
    solution: {}
  },
  watch: {
    solution() {
      this.getProductByCode()
    }
  },
  computed: {
    showList() {
      return this.curType === 1 ? this.softWare : this.hardWare
    }
  },
  data() {
    return {
      curType: 1,
      active: -1,
      types: [
        {
          id: 1,
          name: '软件产品'
        },
        {
          id: 2,
          name: '硬件产品'
        }
      ],
      hardWare: [],
      softWare: []
    }
  },
  mounted() {
    this.getProductByCode()
  },
  methods: {
    //   获取相关产品
    getProductByCode() {
      this.curType = 1
      getProductByCode({ SchemeCode: this.solution.code }).then(res => {
        this.hardWare = res.data.hardWare
        this.softWare = res.data.softWare
        // this.active = this.softWare.length ? this.softWare[0].code : 1
      })
    },
    changeType(id) {
      this.curType = id
      // this.active = this.showList.length ? this.showList[0].code : 1
    },
    onMouseEnter(code) {
      this.active = code
    },
    toProductDetail(item) {
      // let params = Object.assign({}, item);
      // this.$router.push({
      //   name: "SoftwareProductDetail",
      //   params: params
      // });
      if (this.curType === 1) {
        this.$router.push(`/software-product-detail/${item.code}`)
      } else {
        this.$router.push(`/hardware-product-detail/${item.code}/${item.title}`)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import './Index.scss';
</style>
