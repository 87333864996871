<template>
  <div id="job-infos-page">
    <main>
      <div class="left-det-infos">
        <header>{{ jobName }}</header>
        <div class="other-info">
          {{ jobInfos.districtName }} | {{ jobName }} |
          {{ jobInfos.type ? "校招" : "社招" }}
        </div>
        <div class="job-detail">
          <div class="title">职位描述</div>
          <ul>
            <li v-for="(text, i) in responsibility" :key="i">{{ text }}</li>
          </ul>
        </div>
        <div class="job-detail">
          <div class="title">职位要求</div>
          <ul>
            <li v-for="(text, i) in requirements" :key="i">{{ text }}</li>
          </ul>
        </div>
        <div class="job-detail" v-if="others.length > 0">
          <div class="title">其他要求</div>
          <ul>
            <li v-for="(text, i) in others" :key="i">{{ text }}</li>
          </ul>
        </div>
        <div class="join-email">
          简历投递邮箱：
          <i>recruiting@chinaentropy.com</i>
        </div>
        <div class="contacts">
          联系人：
          <i>毛女士</i>
          <i>18612180442</i>
          <i>010-88580888-895</i>
        </div>
        <div class="job-resume" @click="openUpload">简历上传</div>
      </div>
      <div class="right-other-job">
        <header>
          <img src="../../assets/img/title-icon.png" alt /> 相关职位
        </header>
        <div v-if="otherJobList.length > 0">
          <div
            class="job-list"
            v-for="(item, i) in otherJobList"
            :key="i"
            @click="selOtherJob(item)"
          >
            <div class="title">{{ item.title }}</div>
            <div class="info">
              {{ item.districtName }} | {{ item.title }} |
              {{ item.type ? "校招" : "社招" }}
            </div>
          </div>
        </div>
        <div class="non-data" v-else>
          <img :src="noData" alt />
        </div>
      </div>
    </main>
    <el-dialog
      class="upload-cv"
      title="简历上传"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入姓名"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input
            v-model="ruleForm.phone"
            placeholder="请输入手机号码"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input
            v-model="ruleForm.email"
            placeholder="请输入邮箱"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item ref="upload" label="上传简历" prop="fileList">
          <!--                                                :file-list="fileList"
                    -->
          <el-upload
            class="upload-demo"
            action="http://106.112.143.254:18113/upload"
            :data="{ bucketName: 'chinaentropy-website' }"
            :limit="3"
            :on-success="onpHotosSuccess"
            :on-remove="photosRemove"
            :file-list="fileList"
            accept=".pdf, .doc, .docx"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <span class="upload-tips">只支持word、PDF文件上传</span>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getJobInfoList, resume } from '@/api/join.js';

export default {
  data() {
    return {
      jobName: '',
      jobCode: '',
      jobType: '',
      jobInfos: {},

      requirements: [],
      responsibility: [],
      others: [],

      otherJobList: [],
      noData: require('/static/image/join-mod/no-data.png'),

      dialogVisible: false,

      ruleForm: {
        name: '',
        phone: '',
        email: '',
        fileList: []
      },
      fileList: [],
      nameList: [],
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' },
        {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error('请输入姓名'));
            }
            let t = /^[A-Za-z\u4e00-\u9fa5]{2,20}$/;
            if (!t.test(value)) {
              callback(new Error('姓名格式错误'));
            }
            callback();
          },
          trigger: 'blur'
        }
        ],
        phone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入手机号码'));
              }
              let t = /^1[3456789]\d{9}$/;
              if (!t.test(value)) {
                callback(new Error('手机号格式错误'));
              }
              callback();
            },
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入邮箱'));
              }
              let t = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
              if (!t.test(value)) {
                callback(new Error('邮箱格式错误'));
              }
              callback();
            },
            trigger: 'blur'
          }
        ],
        fileList: [{ required: true, message: '请上传简历', trigger: 'blur' }]
      },
      // fileList: []
    };
  },
  mounted() {
    document.getElementById('app-container').scrollTop = 0;
    this.jobName = this.$route.params.name;
    this.jobCode = this.$route.params.jobCode;
    this.jobType = this.$route.params.type;
    this.districtCode = this.$route.params.districtCode;
    this.searchJob();
    this.otherJob();
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$refs['ruleForm'].clearValidate();
        this.$refs['ruleForm'].resetFields();
        this.ruleForm.fileList = [];
        this.fileList = [];
        this.nameList = [];
      }
    }
  },
  methods: {
    selOtherJob(item) {
      this.jobName = item.title;
      this.districtCode = item.districtCode;
      this.searchJob();
      this.otherJob();
    },
    searchJob() {
      let param = {
        activeStatus: 1, //招聘状态（1=启用，2=下架）
        pageNum: 1,
        pageSize: 10,
        title: this.jobName, //标题
        type: this.jobType,
        jobCodes: this.jobCode,
        districtCodes: this.districtCode
      };
      getJobInfoList(param).then(res => {
        console.log(res);
        this.jobInfos = res.data[0];
        let requirements = this.jobInfos.requirements;
        let responsibility = this.jobInfos.responsibility;
        let others = this.jobInfos.others || '';
        this.requirements = requirements.split('\r\n');
        this.responsibility = responsibility.split('\r\n');
        this.others = others && others.split('\r\n');
      });
    },
    otherJob() {
      let param = {
        activeStatus: 1, //招聘状态（1=启用，2=下架）
        pageNum: 1,
        pageSize: 10,
        jobCodes: this.jobCode, //
        type: this.jobType,
        districtCodes: this.districtCode
      };
      let otherJobList = [];
      getJobInfoList(param).then(res => {
        res.data.forEach(el => {
          if (el.title !== this.jobName) {
            otherJobList.push(el);
          }
        });
        this.otherJobList = otherJobList.slice(0, 5);
      });
    },

    openUpload() {
      this.ruleForm = {
        name: '',
        email: '',
        phone: '',
        fileList: []
      };
      this.dialogVisible = true;
    },

    onpHotosSuccess(file) {
      let data = file.data;
      for (let val in data) {
        this.ruleForm.fileList.push(data[val]);
        this.nameList.push(val.split('.')[0]);
      }
      this.$refs['ruleForm'].validate();
    },
    photosRemove(e) {
      let index = this.ruleForm.fileList.findIndex(item => item === e);
      this.ruleForm.fileList.splice(index, 1);
      this.nameList.splice(index, 1);
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let params = {
            email: this.ruleForm.email,
            name: this.ruleForm.name,
            phone: this.ruleForm.phone,
            recruitmentCode: this.jobInfos.code,
            url: this.ruleForm.fileList.join(','),
            urlName: this.nameList.join(',')
          };
          resume(params)
            .then(() => {
              this.$message({ type: 'success', message: '上传简历成功' });
              this.dialogVisible = false;
            })
            .catch(err => {
              console.log(err);
              this.$message.error('上传简历失败！请稍后再试。');
            });
        } else {
          return false;
        }
      });
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index";
</style>
