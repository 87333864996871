import API from './index.js';
const Api = '/api'

// 获取产品解决方案集合
export function getSchemeProduct(params) {
    return API({
        url: Api+"/Scheme/getSchemeProduct",
        method: "GET",
        params
    })
}

// 获取整体解决方案集合
export function getSchemeOverall(params) {
    return API({
        url: Api+"/Scheme/getSchemeOverall",
        method: "GET",
        params
    })
}

// 获取相关产品
export function getProductByCode(params) {
    return API({
        url: Api+"/Scheme/getProductByCode",
        method: "GET",
        params
    })
}

// 获取硬件产品集合-树结构
export function getSchemeTree(params) {
    return API({
        url: Api+"/Scheme/getScheme/Tree",
        method: "GET",
        params
    })
}
