import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
import "@/assets/icon/iconfont-common/iconfont.css";
import "@/assets/icon/iconfont-website/iconfont.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
import VueScroll from "@david-j/vue-j-scroll";
Vue.use(VueScroll);

import AMapLoader from "@amap/amap-jsapi-loader";

window._AMapSecurityConfig = {
  securityJsCode: "955a56e0051a70a9d6a42849f398fae4",
};
// let AMap = null;
AMapLoader.load({
  key: "f17ae99126745047ecdf91f6678ff82d", // key
  plugins: [], // 插件
  version: "2.0",
  Loca: {
    // 是否加载 Loca， 缺省不加载
    version: "2.0.0", // Loca 版本，缺省 1.3.2
  },
})
  .then((amap) => {
    Vue.prototype.$AMap = amap;
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  })
  .catch((err) => {
    throw new Error("加载高德地图失败：" + err);
  });
