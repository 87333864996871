<template>
  <div class="hardware-product-detail">
    <!-- S 顶部大图 -->
    <div class="banner">
      <div class="banner-container">
        <p class="banner-content-title">{{ title }}</p>
      </div>
    </div>
    <!-- E 顶部大图 -->

    <!-- S 产品概述 -->
    <div class="product-overview">
      <mod-title class="text-mod" title="产品概述" enTitle="PRODUCT OVERVIEW" />
      <div class="product-overview-container">
        <div class="img-block">
          <img :src="photo" alt />
        </div>
        <div>
          <p class="title">{{ title }}</p>
          <p class="parent-title">
            <span>{{ parentTitle }}</span>
          </p>
          <p class="overview">{{ overview }}</p>
          <ul class="function-list1" v-if="functionTitles.length === 3">
            <li
              v-for="(item, index) in functionTitles"
              :key="item"
              :class="{
                'function-item': true,
                'function-item-active': active === index,
              }"
              @mouseenter="active = index"
              :style="{
                backgroundImage: `url('${images['Image' + index]}')`,
              }"
            >
              <img :src="images['IconActive' + index]" alt />
              <!-- <p class="icon">
                <span class="iconfont icon-shipin"></span>
              </p>-->
              <p class="title">{{ item }}</p>
              <p class="text" :title="functionTexts[index]">
                {{ functionTexts[index] }}
              </p>
            </li>
          </ul>
          <ul class="function-list2" v-else-if="functionTitles.length === 4">
            <li
              v-for="(item, index) in functionTitles"
              :key="item"
              :class="{
                'function-item': true,
                'function-item-active': active === index,
              }"
              @mouseenter="active = index"
              :style="{
                backgroundImage: `url('${images['Image' + index]}')`,
              }"
            >
              <img :src="images['IconActive' + index]" alt />
              <!-- <p class="icon">
                <span class="iconfont icon-shipin"></span>
              </p>-->
              <p class="title">{{ item }}</p>
              <p class="text" :title="functionTexts[index]">
                {{ functionTexts[index] }}
              </p>
            </li>
          </ul>
          <ul class="function-list3" v-else>
            <li
              v-for="(item, index) in functionTitles"
              :key="item"
              :class="{
                'function-item': true,
                'function-item-active': active === index,
              }"
              @mouseenter="active = index"
              :style="{
                backgroundImage: `url('${images['Image' + index]}')`,
              }"
            >
              <img :src="images['IconActive' + index]" alt />
              <!-- <p class="icon">
                <span class="iconfont icon-shipin"></span>
              </p>-->
              <p class="title">{{ item }}</p>
              <p class="text" :title="functionTexts[index]">
                {{ functionTexts[index] }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- E 产品概述 -->
  </div>
</template>

<script>
import ModTitle from '@/components/mod-title/Index.vue';
import Image0 from '../../../../static/image/product/hardware-detail/0.png';
import Image1 from '../../../../static/image/product/hardware-detail/1.png';
import Image2 from '../../../../static/image/product/hardware-detail/2.png';
import Image3 from '../../../../static/image/product/hardware-detail/3.png';
import Image4 from '../../../../static/image/product/hardware-detail/4.png';
import Image5 from '../../../../static/image/product/hardware-detail/5.png';

import Icon0 from '../../../../static/image/product/hardware-detail/icon0.png';
import Icon1 from '../../../../static/image/product/hardware-detail/icon1.png';
import Icon2 from '../../../../static/image/product/hardware-detail/icon2.png';
import Icon3 from '../../../../static/image/product/hardware-detail/icon3.png';
import Icon4 from '../../../../static/image/product/hardware-detail/icon4.png';
import IconActive0 from '../../../../static/image/product/hardware-detail/icon0-active.png';
import IconActive1 from '../../../../static/image/product/hardware-detail/icon1-active.png';
import IconActive2 from '../../../../static/image/product/hardware-detail/icon2-active.png';
import IconActive3 from '../../../../static/image/product/hardware-detail/icon3-active.png';
import IconActive4 from '../../../../static/image/product/hardware-detail/icon4-active.png';
import { getHardwareDetail } from '@/api/product.js';
export default {
  name: 'HardwareProductDetail',
  components: {
    ModTitle
  },
  data() {
    return {
      images: {
        Image0,
        Image1,
        Image2,
        Image3,
        Image4,
        Image5,
        Icon0,
        Icon1,
        Icon2,
        Icon3,
        Icon4,
        IconActive0,
        IconActive1,
        IconActive2,
        IconActive3,
        IconActive4
      },
      title: null,
      parentTitle: null,
      overview: null,
      photo: null,
      functionTitles: [],
      functionTexts: [],
      active: 0
    };
  },
  watch: {
    $route: {
      handler(val) {
        let code = val.params.code;
        this.parentTitle = val.params.parentTitle;
        this.getHardwareDetail(code);
        this.active = 0;
      },
      immediate: true
    }
  },
  mounted() {
    // let code = this.$route.params.code;
    // this.parentTitle = this.$route.params.parentTitle;
    // this.getHardwareDetail(code);
  },
  methods: {
    getHardwareDetail(code) {
      getHardwareDetail({ code: code })
        .then(res => {
          if (res.code == 200) {
            let data = res.data[0];
            this.title = data.title;
            this.photo = data.photo;
            this.overview = data.overview;
            this.functionTitles = [];
            this.functionTexts = [];
            data.function.split('\r\n').map(item => {
              this.functionTitles.push(item.split('：')[0]);
              this.functionTexts.push(item.split('：')[1]);
            });
            console.log(this.functionTitles, this.functionTexts);
          }
        })
        .catch(err => {
          console.log(err);
          this.title = '获取产品数据失败';
          this.$message.error('获取产品数据失败');
        });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>