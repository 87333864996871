<template>
    <div class="enterprise-qualification">
        <mod-title class="text-mod" title="企业资质" enTitle="ENTERPRISE QUALIFICATION"
                   sentenceText="跨越多领域，涵盖行业经营和技术等各方面资质，护航企业经营"/>
        <div class="enterprise-qualification-top">
            <img
                    :src="`/image/brand-honor/enterprise-qualification/${activeImg.name}${[3,4,5,8].indexOf(activeImg.id)>-1?'':1}.png`"
                    :class="{'img-short': [1,2].indexOf(activeImg.id)>-1}"
            />
        </div>
        <div class="enterprise-qualification-bottom">
            <div v-for="item in list" :key="item.id" :class="`item${item.id === activeImg.id?' active':''}`">
                <img
                        :src="`/image/brand-honor/enterprise-qualification/${item.name}.png`"
                        :class="{'img-thin': item.id === activeImg.id&&item.id === 7}"
                        @click="active = item.id"
                />
                <span>{{ item.name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
  import modTitle from '../../../../components/mod-title/Index'

  export default {
    name: 'enterpriseQualification',
    components: {
      modTitle
    },
    data() {
      return {
        active: 1,
        list: [
          {
            id: 1,
            name: '型号核准证'
          },
          {
            id: 2,
            name: '软件著作权'
          },
          {
            id: 3,
            name: '高新技术企业证书'
          },
          {
            id: 4,
            name: '软件企业证书'
          },
          {
            id: 5,
            name: '科技小巨人'
          },
          {
            id: 6,
            name: '知识产权管理认证证书'
          },
          {
            id: 7,
            name: '中国国家强制性产品认证证书'
          },
          {
            id: 8,
            name: '科技型中小企业'
          }, {
            id: 9,
            name: 'CMMI证书'
          },
          {
            id: 10,
            name: '环境管理体系认证证书'
          },
          {
            id: 11,
            name: '信息安全管理体系认证证书'
          },
          {
            id: 12,
            name: '信息技术服务管理体系认证证书'
          },
          {
            id: 13,
            name: '信息技术服务标准符合性证书'
          },
          {
            id: 14,
            name: '信息系统建设和服务能力等级证书'
          },
          {
            id: 15,
            name: '质量管理体系认证证书'
          },
          {
            id: 16,
            name: '职业健康安全管理体系认证证书'
          }
        ]
      }
    },
    computed: {
      activeImg() {
        return this.list.find(item => item.id === this.active)
      }
    },
    mounted() {
    },
    methods: {}
  }
</script>
<style lang="scss" scoped>
    @import './Index.scss';
</style>
