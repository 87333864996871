<template>
  <div id="mod-title">
    <div class="mod-cn-line">
      <img :src="dark === '0' ? img_light : img_dark" class="left" alt />
      <div class="mod-cn-title" :style="{ color: titleColor }">{{ title }}</div>
      <img :src="dark === '0' ? img_light : img_dark" class="right" alt />
    </div>
    <div class="mod-min-title" :style="{ color: enTitleColor }">{{ enTitle }}</div>
    <div class="sentence-text" v-if="sentenceText != ''">
      <img :src="sentence_start" class="left" alt />
      <div class="sentence" :style="{ color: titleColor }">{{ sentenceText }}</div>
      <img :src="sentence_end" class="right" alt />
    </div>
  </div>
</template>
<script>
export default {
  name: 'modTitle',
  props: {
    title: {
      type: String,
      default: '标题'
    },
    enTitle: {
      type: String,
      default: '英文小标题'
    },
    dark: {
      type: String,
      default: '0'
    },
    titleColor: {
      type: String,
      default: '#2c2f43'
    },
    enTitleColor: {
      type: String,
      // default: "#2c2f4350"
      default: '#ABACB1'
    },
    sentenceText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      img_light: require('../../assets/img/mod-dec-light.png'),
      img_dark: require('../../assets/img/mod-dec-dark.png'),

      sentence_start: require('../../assets/img/sentence-start.png'),
      sentence_end: require('../../assets/img/sentence-end.png')
    }
  }
}
</script>
<style lang="scss" scoped>
#mod-title {
  // width: 562px;
  height: 200px;
  height: 137px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  .mod-cn-line {
    height: 32px;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    .mod-cn-title {
      font-family: SourceHanSansCN-Medium;
      font-size: 32px;
      line-height: 32px;
      letter-spacing: 0;
    }

    img {
      /*position: absolute;*/
      /*top: 86px;*/
      width: 128px;
      height: 20px;
    }

    .left {
      transform: rotate(180deg);
      margin-right: 40px;
    }

    .right {
      margin-left: 40px;
    }
  }

  .mod-min-title {
    font-family: Karmilla-Bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0px;
    margin-top: 16px;
  }
  .sentence-text {
    font-family: Karmilla-Bold;
    height: 50px;
    font-size: 24px;
    // line-height: 16px;
    letter-spacing: 0px;
    .sentence {
      display: inline-block;
      z-index: 9;
    }
    img {
      position: relative;
      z-index: 5;
    }
    .left {
      left: 15px;
      top: 5px;
    }
    .right {
      top: -10px;
      left: 3px;
    }
  }
}
</style>
