<template>
  <div class="app-header">
    <el-header
      :class="
        isHome
          ? homeActive === 1
            ? 'home-header-one'
            : 'home-header-two'
          : headerTheme == 'light'
          ? 'light-header'
          : 'dark-header'
      "
    >
      <div class="header-container">
        <img
          class="logo"
          :src="
            isHome
              ? homeActive === 1
                ? darkLogo
                : lightLogo
              : headerTheme == 'light'
              ? lightLogo
              : darkLogo
          "
          @click="$router.replace('/')"
        />
        <ul :class="headerMenuClass">
          <li
            :class="{
              'menu-item': true,
              'menu-item-home': item.url === '/',
              'menu-item-short': item.url === '/solution',
              'menu-item-product': item.url === '/product-center',
              'menu-item-active': item.url == activeIndex || (item.url.indexOf(activeIndex)>-1 && activeIndex !== '/'),
            }"
            v-for="(item, index) in menuList"
            :key="index"
            @click="headerClick(item, index)"
            @mouseleave="dropVisible = false"
            @mouseenter="
              curType = item.type;
              dropVisible = true;
              dropIndex = index;
            "
          >
            <span>
              {{ item.title }}
              <i
                v-if="
                  item.children ||
                  item.type === 'solution' ||
                  item.type === 'production'
                "
                class="el-icon-arrow-down el-icon--right"
              ></i>
            </span>
            <ul class="drop-block" v-if="item.children && dropVisible && dropIndex == index">
              <li
                v-for="(item1, index1) in item.children"
                @click="headerClick(item1)"
                :class="{
                  'drop-item': true,
                  'drop-item-active': item1.url == activeIndex1,
                }"
                :key="index1"
              >{{ item1.title }}</li>
            </ul>
            <solution-menu v-if="curType === 'solution' && dropVisible && dropIndex == index" @close="close"></solution-menu>
            <production-menu
              v-if="
                curType === 'production' && dropVisible && dropIndex == index
              "
              @close="close"
            />
          </li>
        </ul>
      </div>
    </el-header>
  </div>
</template>

<script>
import SolutionMenu from '@/components/solution-menu/Index.vue'
import productionMenu from '@/components/production-menu/Index.vue'
// import lightLogo from '/static/image/logo.png'
import lightLogo from '/static/image/logo.svg'
// import darkLogo from '/static/image/dark-logo.png'
import darkLogo from '/static/image/dark-logo.svg'

export default {
  name: 'App-Header',
  components: {
    SolutionMenu,
    productionMenu
  },
  data() {
    return {
      lightLogo: lightLogo,
      darkLogo: darkLogo,
      activeIndex: '/',
      activeIndex1: '',
      menuList1: [
        {
          title: '首页',
          url: '/'
        },
        {
          title: '行业',
          url: '/solution',
          type: 'solution'
          // children: [
          //   {
          //     title: '智慧林草',
          //     url: '/solution/智慧林草'
          //   },
          //   {
          //     title: '碳中和',
          //     url: '/solution/碳中和'
          //   },
          //   {
          //     title: '智慧环保',
          //     url: '/solution/智慧环保'
          //   },
          // ]
        },
        {
          title: '产品中心',
          url: '/product-center',
          type: 'production'
          // children: [
          //   {
          //     title: '软件产品',
          //     url: '/software-product'
          //   },
          //   {
          //     title: '硬件产品',
          //     url: '/hardware-product'
          //   }
          // ]
        },
        {
          title: '品牌荣誉',
          url: '/brand-honor'
        },
        // {
        //   title: '咨询动态',
        //   url: '5'
        // },
        {
          title: '关于汉熵',
          url: '/on-china-entropy'
        },
        {
          title: '加入我们',
          url: '/join-us'
        }
      ],
      menuList2: [
        {
          title: '招聘职位',
          url: '/job-list/0/0'
        },
        {
          title: '加入我们',
          url: '/join-us'
        },
        {
          title: '校园招聘',
          url: '/job-list/1/0'
        },
        {
          title: '关于汉熵',
          url: '/on-china-entropy'
        }
      ],
      headerTheme: 'dark',
      dropVisible: false,
      dropIndex: 0,
      curType: ''
    }
  },
  watch: {
    // 监听 路由变化 初始滚动条
    $route: {
      handler(val) {
        this.activeIndex = val.fullPath
        console.log(val.fullPath)
        if (val.fullPath.indexOf('/job-list/1') > -1) {
          this.activeIndex = '/job-list/1/0'
        }
        if (val.fullPath.indexOf('/job-list/0') > -1) {
          this.activeIndex = '/job-list/0/0'
        }
        if (val.fullPath.indexOf('/job-infos/0') > -1) {
          this.activeIndex = '/job-list/0/0'
        }
        if (val.fullPath.indexOf('/job-infos/1') > -1) {
          this.activeIndex = '/job-list/1/0'
        }

        // if (val.fullPath == '/solution/智慧林草') {
        //   this.activeIndex = '/solution'
        //   this.activeIndex1 = '/solution/智慧林草'
        // }
        // if (val.fullPath == '/solution/碳中和') {
        //   this.activeIndex = '/solution'
        //   this.activeIndex1 = '/solution/碳中和'
        // }
        // if (val.fullPath == '/solution/智慧环保') {
        //   this.activeIndex = '/solution'
        //   this.activeIndex1 = '/solution/智慧环保'
        // }

        if (val.fullPath == '/software-product') {
          this.activeIndex = '/product-center'
          this.activeIndex1 = '/software-product'
        }
        if (val.fullPath == '/hardware-product') {
          this.activeIndex = '/product-center'
          this.activeIndex1 = '/hardware-product'
        }
        if (val.fullPath.indexOf('/software-product-detail') > -1) {
          this.activeIndex = '/product-center'
          this.activeIndex1 = '/software-product'
        }
        if (val.fullPath.indexOf('/hardware-product-detail') > -1) {
          this.activeIndex = '/product-center'
          this.activeIndex1 = '/hardware-product'
        }
        if (val.fullPath.indexOf('/solution') > -1) {
          this.activeIndex = '/solution'
        }
        this.headerTheme = val.meta.headerTheme
        this.setScreenTopTo0()
      },
      immediate: true
    }
  },
  computed: {
    menuList: function () {
      if (this.activeIndex.indexOf('/job-list') > -1 || this.activeIndex.indexOf('/job-infos') > -1) {
        return this.menuList2
      } else {
        return this.menuList1
      }
    },
    isHome() {
      return ['/', '/home'].includes(this.$route.path)
    },
    homeActive() {
      return this.$store.getters.homeActive
    },
    headerMenuClass() {
      let result = ''
      if (this.isHome) {
        if (this.homeActive === 1) {
          result = 'header-menu dark-header-menu'
        } else if (this.homeActive === 2) {
          result = 'header-menu light-header-menu'
        } else {
          // result = 'header-menu three-header-menu'
          result = 'header-menu light-header-menu'
        }
      } else {
        result = this.headerTheme === 'light' ? 'header-menu light-header-menu' : 'header-menu dark-header-menu'
      }
      return result
    }
  },
  methods: {
    headerClick(item, index) {
      console.log(item, index)
      if (item.children || item.type === 'solution') {
        this.dropVisible = true
        this.dropIndex = index
      } else if (item.children || item.type === 'production') {
        this.dropVisible = true
        this.dropIndex = index
      } else {
        this.activeIndex = item.url
        this.$router.push(item.url)
      }
    },
    setScreenTopTo0() {
      document.documentElement.scrollTop = 0
    },
    close() {
      this.dropVisible = false
      this.curType = ''
    }
  }
}
</script>

<style lang="scss">
.app-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  z-index: 1000;

  & > .el-header {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    font-size: 14px;

    & > .header-container {
      width: 1280px;
      height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > .logo {
        width: 119px;
        height: 34px;
        cursor: pointer;
      }

      & > .light-header-menu {
        & > .menu-item {
          & > span {
            color: #fff;
          }
        }
      }

      & > .dark-header-menu {
        & > .menu-item {
          & > span {
            color: #000;
          }
        }
      }

      & > .three-header-menu {
        & > .menu-item {
          & > span {
            color: #8f959e;
          }
        }

        & > .menu-item-active {
          & > span {
            color: #fff !important;
          }

          &::after {
            background: #fff !important;
          }
        }
      }

      & > .header-menu {
        height: 64px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: space-around;

        & > .menu-item {
          position: relative;
          width: 120px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          cursor: pointer;
          /*text-align: center;*/

          &-active {
            & > span {
              color: #3170ff;
            }

            &::after {
              position: absolute;
              content: '';
              bottom: 0;
              /*left: 50%;*/
              left: 30px;
              width: 60px;
              height: 2px;
              background: #3170ff;
              /*transform: translateX(-50%);*/
            }
          }

          & > span {
            &:hover {
              color: #3170ff;
            }
          }

          & > .drop-block {
            padding: 5px;
            line-height: 36px;
            background: #fff;
            border: 1px solid #ebeef5;
            border-radius: 4px;
            box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);

            & > .drop-item {
              &-active {
                color: #3170ff;
              }

              &:hover {
                color: #3170ff;
              }
            }
          }
        }

        & > .menu-item-home::after {
          width: 30px;
          left: 55px;
        }

        & > .menu-item-short::after {
          width: 30px;
          left: 35px;
        }

        & > .menu-item-product::after {
          left: 20px;
        }

        & > .menu-item-home::after {
          left: 45px;
        }
      }
    }
  }

  & > .home-header-one {
    background: rgba(#edf5f8, 20%);
    backdrop-filter: blur(5px);
  }

  & > .home-header-two {
    background: rgba(#000000, 10%);
    backdrop-filter: blur(5px);
  }

  & > .light-header {
    background: rgba($color: #000, $alpha: 0.5);
  }

  & > .dark-header {
    background: rgba($color: #fff, $alpha: 0.4);
  }
}
</style>
