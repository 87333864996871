<template>
  <div id="production-menu">
    <div class="out-box">
      <div class="left">
        <div class="tab-title">
          <span @click="showProduction()">软件产品</span>
        </div>
        <div class="list-box">
          <div
            :class="`child${activeCode === item.code ? ' active' : ''}`"
            v-for="(item, i) in softwareList"
            :key="i"
            @click="showProduction()"
            @mouseenter="onMouseEnter(item, 0)"
          >{{ item.title }}</div>
        </div>
        <div class="tab-title">
          <span @click="showProduction()">硬件产品</span>
        </div>
        <div class="list-box">
          <div
            :class="`child${activeCode === item.code ? ' active' : ''}`"
            v-for="(item, i) in hardwareList"
            :key="i"
            @click="showProduction()"
            @mouseenter="onMouseEnter(item, 1)"
          >{{ item.title }}</div>
        </div>
      </div>
      <div class="right" v-if="!childList[0].children">
        <div class="list" v-for="(child, i) in childList" :key="i" @click="showMore(child)">
          <div class="img-box">
            <img
              :src="
              child.photo
                ? child.photo
                : photoList[i]
                ? photoList[i]
                : photoList[0]
            "
              alt
            />
          </div>
          <div class="infos">
            <div class="title">{{ child.title }}</div>
            <div class="content">{{ child.overview }}</div>
          </div>
        </div>
      </div>
      <div class="right" v-else>
        <div class="more-list-box" v-if="proType == 0">
          <div class="box-content" v-for="(child, i) in childList" :key="i">
            <header>
              <img src="../../assets/img/title-icon.png" alt />
              {{ child.title }}
            </header>
            <div class="child">
              <div class="child-list" v-for="(item, i) in child.children" :key="i" @click="showMore(item)">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div class="hardware-more-list" v-else>
          <div class="left">
            <div
              :class="`child${hardwareImgActive === i ? ' active' : ''}`"
              v-for="(item, i) in childList"
              :key="i"
              @mouseenter="onMouseEnterHardWare(item, i)"
            >
              <img
                :src="
                hardwareImgList[
                  `${hardwareImgActive === i ? 'active' : 'normal'}${i + 1}`
                ]
              "
                alt
              />
              {{ item.title }}
            </div>
          </div>
          <div class="right">
            <div class="list" v-for="(child, i) in hardWareChildList" :key="i" @click="showMore(child)">
              <div class="img-box">
                <img
                  :src="
                  child.photo
                    ? child.photo
                    : photoList[i]
                    ? photoList[i]
                    : photoList[0]
                "
                  alt
                />
              </div>
              <div class="infos">
                <div class="title">{{ child.title }}</div>
                <div class="content">{{ child.overview }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'productionMenu',
  props: {},
  computed: {
    hardwareList() {
      return this.$store.getters.hardwareList
    },
    softwareList() {
      return this.$store.getters.softwareList
    },
    list() {
      return this.proType === 0 ? this.softwareList : this.hardwareList
    },
    childList() {
      return this.proType === 0
        ? this.softwareList.find(({ code }) => {
            return this.activeCode == code
          }).children
        : this.hardwareList.find(({ code }) => {
            return this.activeCode == code
          }).children
    }
  },
  data() {
    return {
      proType: 0,
      activeCode: '001',
      activeTitle: '',
      activeProduct: '',
      lastClickProduct: '',

      productionTypePath: ['/software-product', '/hardware-product'],

      photoList: [
        require('/static/image/product/1.png'),
        require('/static/image/product/2.png'),
        require('/static/image/product/3.png'),
        require('/static/image/product/4.png'),
        require('/static/image/product/5.png'),
        require('/static/image/product/6.png')
      ],
      hardwareImgList: {
        active1: require('/static/image/product/hardware/1-1.png'),
        active2: require('/static/image/product/hardware/2-1.png'),
        active3: require('/static/image/product/hardware/3-1.png'),

        normal1: require('/static/image/product/hardware/1-2.png'),
        normal2: require('/static/image/product/hardware/2-2.png'),
        normal3: require('/static/image/product/hardware/3-2.png')
      },
      hardwareImgActive: 0,
      hardWareChildList: []
    }
  },
  created() {
    let proType = this.productionTypePath.indexOf(this.$route.path)

    if (proType > 0) {
      this.proType = 1
    } else if (proType == 0) {
      this.proType = 0
    } else {
      let hDetail = this.$route.path.includes('/hardware-product')
      if (hDetail) {
        this.proType = 1
      } else {
        this.proType = 0
      }
    }
    this.showFirst()
  },
  mounted() {},
  methods: {
    selProType(type) {
      this.proType = type
      this.showFirst()
    },
    showProduction() {
      this.$router.push(this.productionTypePath[this.proType])
      this.$emit('close')
    },
    showFirst() {
      if (this.proType === 0) {
        this.activeCode = this.softwareList[0].code
        this.activeTitle = this.softwareList[0].title
      } else {
        this.activeCode = this.hardwareList[0].code
        this.activeTitle = this.hardwareList[0].title
      }
    },
    onMouseEnter(item, type) {
      this.proType = type
      this.activeCode = item.code
      this.activeTitle = item.title
      if (item.parentCode === '0' && item.code === '01') {
        this.hardWareChildList = this.childList[0].children || []
      }
      document.getElementsByClassName('right')[0].scrollTop = 0
    },
    onMouseEnterHardWare(item, k) {
      this.hardWareChildList = item.children || []
      this.hardwareImgActive = k
    },
    showMore(item) {
      if (this.proType) {
        this.$router.push(`/hardware-product-detail/${item.code}/${this.activeTitle}`)
      } else {
        this.$router.push(`/software-product-detail/${item.code}`)
      }
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
