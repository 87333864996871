var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home",style:({
    backgroundColor:
      _vm.active === 1 ? '#BDCCE0' : _vm.active === 2 ? '#021023' : '#070D16',
  }),on:{"wheel":_vm.goWheel}},[_c('div',{staticClass:"tabs"},[_vm._l((_vm.tabs),function(item){return _c('div',{key:item.id,class:("tab " + (item.id === _vm.active ? ' active' : '') + "  " + (_vm.active === 1 ? 'light' : '') + " " + (_vm.active === 1 && item.id === 1 ? 'light-back' : '')),on:{"click":function($event){return _vm.changeActive(item.id)}}})}),_c('div',{class:("control-autoplay " + (_vm.active === 1 ? 'control-autoplay-light' : '')),on:{"click":_vm.changeIsAutoPlay}},[_c('img',{attrs:{"src":("/image/home/" + (_vm.isAutoPlay
            ? 'pause' + (_vm.active === 1 ? '-light' : '')
            : 'start' + (_vm.active === 1 ? '-light' : '')) + ".png")}})])],2),_c('el-carousel',{ref:"swiper",attrs:{"autoplay":_vm.isAutoPlay,"interval":_vm.interval,"arrow":"never","height":_vm.active === 3 ? '1080px' : '937px',"direction":"vertical"},on:{"change":_vm.changeCarousel}},[_c('el-carousel-item',[_c('video',{ref:"video",attrs:{"src":"/image/home/home.mp4","autoplay":"true","loop":"true","muted":""},domProps:{"muted":true}}),_vm._l((_vm.industryList),function(industry){return _c('div',{key:industry.id,staticClass:"industry-wrap",style:({
          left: industry.left + 'px',
          top: industry.top + 'px',
          width: (industry.w ? industry.w : '73') + 'px',
          height: (industry.h ? industry.h : '53') + 'px',
        }),on:{"click":function($event){return _vm.$router.push(industry.href)}}})})],2),_c('el-carousel-item',[_c('img',{staticClass:"back-img",attrs:{"src":"/image/home/home-two.png"}}),_c('img',{staticClass:"outside-img",attrs:{"src":"/image/home/home-two-outside.png"}}),_c('img',{staticClass:"headside-img",attrs:{"src":"/image/home/home-two-headside.png"}}),_vm._l((_vm.list),function(item){return _c('div',{key:item.id,class:("box box" + (item.id))},[_c('img',{attrs:{"src":("/image/home/" + (item.name) + ".png")}}),_c('span',[_vm._v(_vm._s(item.name))])])})],2),_c('el-carousel-item',[_c('img',{staticClass:"back-img",staticStyle:{"width":"1920px"},attrs:{"src":"/image/home/home-three.png"}}),_c('div',{staticClass:"href href-three",on:{"click":function($event){return _vm.$router.push('/job-list/0/0')}}},[_vm._v(" 查看详情 ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }