<template>
  <div class="software-product">
    <!-- S 顶部大图 -->
    <div class="banner">
      <div class="banner-container">
        <p class="banner-content-title">软件产品</p>
      </div>
    </div>
    <!-- E 顶部大图 -->

    <!-- S 基础支撑平台及数据中台 -->
    <div class="foundation-support">
      <mod-title class="text-mod" title="基础支撑平台及数据中台" enTitle="FOUNDATION SUPPORT PLATFORM AND DATA CONSOLE" />
      <div class="foundation-support-container">
        <ul class="foundation-support-list">
          <li
            :class="{
              'foundation-support-item': true,
              'foundation-support-item-active':
                foundationSupportActive === index,
            }"
            v-for="(item, index) in foundationSupportData"
            :key="index"
            @mouseenter="foundationSupportActive = index"
            @mouseleave="foundationSupportActive = null"
            @click="goToDetail(item)"
          >
            <!-- <div
              class="active-mask"
              v-if="foundationSupportActive === index"
            ></div>-->
            <img :src="images[`Image${index + 1}`]" alt />
            <p class="title">{{ item.title }}</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- E 基础支撑平台及数据中台 -->

    <!-- S 算法中台及多媒体指挥系统 -->
    <div class="algorithm-console">
      <mod-title
        class="text-mod"
        title="算法中台及多媒体指挥系统"
        enTitle="ALGORITHM CONSOLE AND MULTIMEDIA COMMAND SYSTEM"
        titleColor="#ffffff"
        enTitleColor="#c8d3ed30"
        dark="1"
      />
      <div class="algorithm-console-container">
        <ul class="algorithm-console-list">
          <template v-for="(item, index) in algorithmConsoletData">
            <li
              :class="{
                'algorithm-console-item': true,
                'algorithm-console-item-first': index === 0,
                'algorithm-console-item-second': index === 1,
                'algorithm-console-item-normal': index > 1,
              }"
              :key="index"
              @mouseenter="algorithmConsoleActive = index"
              @click="goToDetail(item)"
            >
              <img :src="images[`Image${index + 5}`]" alt />
              <p class="title">{{ item.title }}</p>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <!-- E 算法中台及多媒体指挥系统 -->

    <!-- S 人工智能业务平台 -->
    <div class="artificial-intelligence">
      <mod-title class="text-mod" title="人工智能业务平台" enTitle="ARTIFICIAL INTELLIGENCE PLATFORM" />
      <div class="artificial-intelligence-container">
        <div>
          <ul class="artificial-intelligence-tabs">
            <li
              :class="{
                'artificial-intelligence-item': true,
                'artificial-intelligence-item-active':
                  artificialIntelligenceActive === index,
              }"
              v-for="(item, index) in artificialIntelligenceData"
              :key="index"
              @click="tabChange(item, index)"
            >{{ item.title }}</li>
          </ul>
          <div class="artificial-intelligence-content">
            <ul class="content-list" v-if="artificialIntelligenceData[artificialIntelligenceActive]">
              <li
                :class="{
                  'content-item': true,
                  'content-item-active': activeIndex === index,
                }"
                v-for="(item, index) in artificialIntelligenceData[
                  artificialIntelligenceActive
                ].children"
                :key="index"
                @mouseenter="showDetail(item, index)"
                @click="goToDetail(item)"
              >
                <p class="title">{{ item.title }}</p>
                <p class="overview">{{ item.overview }}</p>
                <img :src="
                    activeIndex === index ? images.ArrowActive : images.Arrow
                  " />
              </li>
            </ul>
            <div class="detail">
              <p class="title">{{ currentData.title }}</p>
              <p class="overview">{{ currentData.overview }}</p>
              <p class="show-detail" @click="goToDetail(currentData)">查看详情</p>
              <!--              <img-->
              <!--                :src="-->
              <!--                  images[-->
              <!--                    `ArtificialIntelligenceActiveImage${artificialIntelligenceActive}`-->
              <!--                  ]-->
              <!--                "-->
              <!--                alt=""-->
              <!--              />-->
              <img :src="currentData.photo?currentData.photo:'/image/solution/solution-default.jpg'" alt />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- E 人工智能业务平台 -->
  </div>
</template>

<script>
import ModTitle from '@/components/mod-title/Index.vue'
import Image1 from '../../../static/image/product/1.png'
import Image2 from '../../../static/image/product/2.png'
import Image3 from '../../../static/image/product/3.png'
import Image4 from '../../../static/image/product/4.png'
import Image5 from '../../../static/image/product/5.png'
import Image6 from '../../../static/image/product/6.png'
import Image7 from '../../../static/image/product/7.png'
import Image8 from '../../../static/image/product/8.png'
import Image9 from '../../../static/image/product/9.png'
import Image10 from '../../../static/image/product/10.png'

import ArtificialIntelligenceActiveImage0 from '../../../static/image/product/zhlc.png'
import ArtificialIntelligenceActiveImage1 from '../../../static/image/product/tzh.png'
import ArtificialIntelligenceActiveImage2 from '../../../static/image/product/zhhb.png'
import ArtificialIntelligenceActiveImage3 from '../../../static/image/product/zhcg.png'
import ArtificialIntelligenceActiveImage4 from '../../../static/image/product/zhyjjjfa.png'
import ArtificialIntelligenceActiveImage5 from '../../../static/image/product/zhgd.png'
import ArtificialIntelligenceActiveImage6 from '../../../static/image/product/zhga.png'
import ArtificialIntelligenceActiveImage7 from '../../../static/image/product/zhsl.png'
import ArtificialIntelligenceActiveImage8 from '../../../static/image/product/zhxy.png'

import Arrow from '../../../static/image/product/arrow.png'
import ArrowActive from '../../../static/image/product/arrow-active.png'
import { getSoftwareTree } from '@/api/product.js'
export default {
  name: 'SoftwareProduct',
  components: {
    ModTitle
  },
  data() {
    return {
      images: {
        Image1,
        Image2,
        Image3,
        Image4,
        Image5,
        Image6,
        Image7,
        Image8,
        Image9,
        Image10,
        Arrow,
        ArrowActive,
        ArtificialIntelligenceActiveImage0,
        ArtificialIntelligenceActiveImage1,
        ArtificialIntelligenceActiveImage2,
        ArtificialIntelligenceActiveImage3,
        ArtificialIntelligenceActiveImage4,
        ArtificialIntelligenceActiveImage5,
        ArtificialIntelligenceActiveImage6,
        ArtificialIntelligenceActiveImage7,
        ArtificialIntelligenceActiveImage8
      },
      foundationSupportActive: null,
      foundationSupportData: [],
      algorithmConsoleActive: 0,
      algorithmConsoletData: [],
      artificialIntelligenceActive: 0,
      artificialIntelligenceData: [],
      activeIndex: 0,
      currentData: {
        title: null,
        overview: null
      }
    }
  },
  mounted() {
    this.getSoftwareTree()
  },
  methods: {
    getSoftwareTree() {
      getSoftwareTree()
        .then(res => {
          if (res.code == 200) {
            res.data.map(item => {
              if (item.code == '001') {
                this.foundationSupportData = item.children || []
              }
              if (item.code == '002') {
                this.algorithmConsoletData = item.children || []
              }
              if (item.code == '003') {
                this.artificialIntelligenceData = item.children || []
                this.currentData = Object.assign({}, this.artificialIntelligenceData[0].children[0])
              }
            })
          } else {
            this.$message.error('获取产品数据失败')
          }
        })
        .catch(e => {
          console.log(e)
          this.$message.error('获取产品数据失败')
        })
    },
    goToDetail(item) {
      // let params = Object.assign({}, item);

      this.$router.push(`/software-product-detail/${item.code}`)
      // {
      //   name: "SoftwareProductDetail",
      //     params: params;
      // });
    },
    tabChange(item, index) {
      this.artificialIntelligenceActive = index
      this.currentData = Object.assign({}, item.children[0])
    },
    showDetail(item, index) {
      this.activeIndex = index
      this.currentData = Object.assign({}, item)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
