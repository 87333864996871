<template>
  <div class="core-technology">
    <mod-title class="text-mod" title="核心技术" enTitle="CORE TECHNOLOGY" sentenceText="完备的自主核心技术体系支撑工业物联网全链条重要环节" />
    <div class="core-technology-list">
      <div v-for="item in list" :key="item.id" class="item">
        <img :src="`/image/brand-honor/core-technology/${item.name}.png`" />
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import modTitle from '../../../../components/mod-title/Index.vue'

export default {
  name: 'CoreTechnology',
  components: {
    modTitle
  },
  data() {
    return {
      list: [
        {
          id: 1,
          name: '多模异构无线承载物联网'
        },
        {
          id: 2,
          name: '物联网SoC芯片技术'
        },
        {
          id: 3,
          name: '湖仓一体数据处理技术'
        },
        {
          id: 4,
          name: '市域级3D模型可视化技术'
        },
        {
          id: 5,
          name: '图像识别及人工智能技术'
        },
        {
          id: 6,
          name: '高清对讲及视频指挥通信技术'
        },
        {
          id: 7,
          name: '流媒体音视频技术'
        },
        {
          id: 8,
          name: '物联网安全防护技术'
        }
      ]
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
@import './Index.scss';
</style>
