<template>
  <div class="winter-olympics-page">
    <img :src="WinterOlympicsImg" alt="" />
  </div>
</template>

<script>
import WinterOlympicsImg from '/public/image/winter-olympics.jpg';
export default {
  name: 'WinterOlympics',
  components: {},
  data() {
    return {
      WinterOlympicsImg
    };
  },
}
</script>
<style lang="scss" scoped>
@import "./Index.scss";
</style>
