var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"software-product"},[_vm._m(0),_c('div',{staticClass:"foundation-support"},[_c('mod-title',{staticClass:"text-mod",attrs:{"title":"基础支撑平台及数据中台","enTitle":"FOUNDATION SUPPORT PLATFORM AND DATA CONSOLE"}}),_c('div',{staticClass:"foundation-support-container"},[_c('ul',{staticClass:"foundation-support-list"},_vm._l((_vm.foundationSupportData),function(item,index){return _c('li',{key:index,class:{
            'foundation-support-item': true,
            'foundation-support-item-active':
              _vm.foundationSupportActive === index,
          },on:{"mouseenter":function($event){_vm.foundationSupportActive = index},"mouseleave":function($event){_vm.foundationSupportActive = null},"click":function($event){return _vm.goToDetail(item)}}},[_c('img',{attrs:{"src":_vm.images[("Image" + (index + 1))],"alt":""}}),_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.title))])])}),0)])],1),_c('div',{staticClass:"algorithm-console"},[_c('mod-title',{staticClass:"text-mod",attrs:{"title":"算法中台及多媒体指挥系统","enTitle":"ALGORITHM CONSOLE AND MULTIMEDIA COMMAND SYSTEM","titleColor":"#ffffff","enTitleColor":"#c8d3ed30","dark":"1"}}),_c('div',{staticClass:"algorithm-console-container"},[_c('ul',{staticClass:"algorithm-console-list"},[_vm._l((_vm.algorithmConsoletData),function(item,index){return [_c('li',{key:index,class:{
              'algorithm-console-item': true,
              'algorithm-console-item-first': index === 0,
              'algorithm-console-item-second': index === 1,
              'algorithm-console-item-normal': index > 1,
            },on:{"mouseenter":function($event){_vm.algorithmConsoleActive = index},"click":function($event){return _vm.goToDetail(item)}}},[_c('img',{attrs:{"src":_vm.images[("Image" + (index + 5))],"alt":""}}),_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.title))])])]})],2)])],1),_c('div',{staticClass:"artificial-intelligence"},[_c('mod-title',{staticClass:"text-mod",attrs:{"title":"人工智能业务平台","enTitle":"ARTIFICIAL INTELLIGENCE PLATFORM"}}),_c('div',{staticClass:"artificial-intelligence-container"},[_c('div',[_c('ul',{staticClass:"artificial-intelligence-tabs"},_vm._l((_vm.artificialIntelligenceData),function(item,index){return _c('li',{key:index,class:{
              'artificial-intelligence-item': true,
              'artificial-intelligence-item-active':
                _vm.artificialIntelligenceActive === index,
            },on:{"click":function($event){return _vm.tabChange(item, index)}}},[_vm._v(_vm._s(item.title))])}),0),_c('div',{staticClass:"artificial-intelligence-content"},[(_vm.artificialIntelligenceData[_vm.artificialIntelligenceActive])?_c('ul',{staticClass:"content-list"},_vm._l((_vm.artificialIntelligenceData[
                _vm.artificialIntelligenceActive
              ].children),function(item,index){return _c('li',{key:index,class:{
                'content-item': true,
                'content-item-active': _vm.activeIndex === index,
              },on:{"mouseenter":function($event){return _vm.showDetail(item, index)},"click":function($event){return _vm.goToDetail(item)}}},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"overview"},[_vm._v(_vm._s(item.overview))]),_c('img',{attrs:{"src":_vm.activeIndex === index ? _vm.images.ArrowActive : _vm.images.Arrow}})])}),0):_vm._e(),_c('div',{staticClass:"detail"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.currentData.title))]),_c('p',{staticClass:"overview"},[_vm._v(_vm._s(_vm.currentData.overview))]),_c('p',{staticClass:"show-detail",on:{"click":function($event){return _vm.goToDetail(_vm.currentData)}}},[_vm._v("查看详情")]),_c('img',{attrs:{"src":_vm.currentData.photo?_vm.currentData.photo:'/image/solution/solution-default.jpg',"alt":""}})])])])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"banner-container"},[_c('p',{staticClass:"banner-content-title"},[_vm._v("软件产品")])])])}]

export { render, staticRenderFns }