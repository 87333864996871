export default function AJAX(opt) {
    let url = opt.url;
    const headers = opt.headers || {};
    const method = opt.method || 'GET';
    const params = opt.params;
    const data = opt.data;
    const form = opt.form;
    const timeout = opt.timeout;
    const onUploadProgress = opt.onUploadProgress
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.timeout = timeout || 30000;
        if (params) {
            let str = "?";
            for (const key in params) {
                str += `${key}=${params[key]}&`;
            }
            url += str.substr(0, str.lastIndexOf("&"));
        }
        xhr.open(method, url);
        for (const key in headers) {
            xhr.setRequestHeader(key, headers[key]);
        }
        // 注册事件
        xhr.onload = () => {
            if (xhr.status == 200) {
                let res;
                try {
                    res = JSON.parse(xhr.responseText)
                } catch {
                    res = {
                        code: 200,
                        data: xhr.responseText,
                    }
                }
                resolve(res);
            } else {
                reject({
                    code: xhr.status,
                    message: xhr.statusText
                })
            }
        }
        xhr.onerror = (error) => {
            console.warn("xhr出现错误", error);
            reject({
                code: -1,
                message: "xhr出现错误"
            });
        }
        xhr.ontimeout = () => {
            reject({
                code: -1,
                message: "请求超时"
            });
        }
        if (onUploadProgress) xhr.upload.onprogress = onUploadProgress
        // 填写数据，发送请求
        if (data) {
            let dataStr = "";
            if (headers["Content-type"] === "application/json") {
                dataStr = JSON.stringify(data);
            } else if (headers["Content-type"] === "application/x-www-form-urlencoded") {
                for (const key in data) {
                    dataStr += `${key}=${data[key]}&`;
                }
                dataStr = dataStr.substr(0, dataStr.lastIndexOf("&"));
            }
            xhr.send(dataStr);
        } else if (form) {
            xhr.send(form);
        } else {
            xhr.send();
        }
    })
}