<template>
  <div
    class="home"
    :style="{
      backgroundColor:
        active === 1 ? '#BDCCE0' : active === 2 ? '#021023' : '#070D16',
    }"
    @wheel="goWheel"
  >
    <div class="tabs">
      <div
        v-for="item in tabs"
        :key="item.id"
        :class="`tab ${item.id === active ? ' active' : ''}  ${
          active === 1 ? 'light' : ''
        } ${active === 1 && item.id === 1 ? 'light-back' : ''}`"
        @click="changeActive(item.id)"
      ></div>
      <div
        :class="`control-autoplay ${
          active === 1 ? 'control-autoplay-light' : ''
        }`"
        @click="changeIsAutoPlay"
      >
        <img
          :src="`/image/home/${
            isAutoPlay
              ? 'pause' + (active === 1 ? '-light' : '')
              : 'start' + (active === 1 ? '-light' : '')
          }.png`"
        />
      </div>
    </div>
    <el-carousel
      ref="swiper"
      :autoplay="isAutoPlay"
      :interval="interval"
      arrow="never"
      :height="active === 3 ? '1080px' : '937px'"
      direction="vertical"
      @change="changeCarousel"
    >
      <el-carousel-item>
        <!--                <div class="title">-->
        <!--                    汉熵通信-->
        <!--                </div>-->
        <!--                <div class="subtitle">-->
        <!--                    “下一代工业物联网缔造者”-->
        <!--                </div>-->
        <!--                <div class="desc">-->
        <!--                    万物互联-->
        <!--                    <span style="margin-left: 20px">开启无限可能</span>-->
        <!--                </div>-->
        <!--                <div class="href" @click="$router.push('on-china-entropy')">-->
        <!--                    查看详情-->
        <!--                </div>-->
        <video
          src="/image/home/home.mp4"
          ref="video"
          autoplay="true"
          loop="true"
          muted
        ></video>
        <!-- <img class="back-img" src="/image/home/new_home_1.png" /> -->

        <div
          v-for="industry in industryList"
          :key="industry.id"
          class="industry-wrap"
          :style="{
            left: industry.left + 'px',
            top: industry.top + 'px',
            width: (industry.w ? industry.w : '73') + 'px',
            height: (industry.h ? industry.h : '53') + 'px',
          }"
          @click="$router.push(industry.href)"
        >
          <!-- {{industry.name}} -->
          <!-- <div class="industry" @mouseover="activeIndustry = industry"></div>
          <template v-if="activeIndustry.id === industry.id">
            <div v-if="industry.id === 0" class="industry-entropy" @mouseleave="activeIndustry = null">
              <div class="entropy-href" @click="$router.push('on-china-entropy')">查看详情</div>
            </div>
          </template>
          <div
            :class="`industry-over ${activeIndustry.id === industry.id && industry.id > 0?'industry-over-active':''}`"
            @mouseleave="activeIndustry = {id:-1}"
            @click="toHref"
          >
            <template v-if="activeIndustry.id === industry.id && industry.id > 0">
              <img :src="`/image/home/industry/${industry.name}.png`" class="img-name" />
              <span>{{ activeIndustry.name }}</span>
              <img src="/image/home/industry/arrow.png" class="img-arrow" />
            </template>
          </div>-->
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <img class="back-img" src="/image/home/home-two.png" />
        <img class="outside-img" src="/image/home/home-two-outside.png" />
        <img class="headside-img" src="/image/home/home-two-headside.png" />
        <div v-for="item in list" :key="item.id" :class="`box box${item.id}`">
          <img :src="`/image/home/${item.name}.png`" />
          <span>{{ item.name }}</span>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <!--                height: 1080px;object-fit: cover-->
        <img
          class="back-img"
          style="width: 1920px"
          src="/image/home/home-three.png"
        />
        <div class="href href-three" @click="$router.push('/job-list/0/0')">
          查看详情
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      isAutoPlay: true,
      first: true,
      interval: 5000,
      ctrlKey: 0,
      tabs: [{ id: 1 }, { id: 2 }, { id: 3 }],
      list: [
        {
          id: 1,
          name: '交通'
        },
        {
          id: 2,
          name: '工地'
        },
        {
          id: 3,
          name: '大气'
        },
        {
          id: 4,
          name: '安全生产'
        },
        {
          id: 5,
          name: '森林'
        },
        {
          id: 6,
          name: '湖泊'
        },
        {
          id: 7,
          name: '社区'
        }
      ],
      activeIndustry: {
        id: -1
      },
      industryList: [
        {
          id: 0,
          name: '汉熵通信',
          href: 'on-china-entropy',
          left: 942,
          top: 116,
          w: 260,
          h: 220
        },
        {
          id: 1,
          name: '城市应急',
          href: '/solution/智慧应急解决方案',
          left: 521,
          top: 212
        },
        {
          id: 2,
          name: '智慧工地',
          href: '/solution/智慧工地解决方案',
          left: 411,
          top: 484
        },
        {
          id: 3,
          name: '智慧公安',
          href: '/solution/智慧公安解决方案',
          left: 1081,
          top: 591
        },
        {
          id: 4,
          name: '森林防火',
          href: '/solution/智慧林草解决方案',
          left: 1438,
          top: 90
        },
        {
          id: 5,
          name: '智慧环保',
          href: '/solution/智慧环保解决方案',
          left: 1665,
          top: 374
        },
        {
          id: 6,
          name: '智慧城管',
          href: '/solution/智慧城管解决方案',
          left: 1502,
          top: 484
        },
        {
          id: 7,
          name: '智慧水利',
          href: '/solution/智慧水利解决方案',
          left: 1537,
          top: 242
        },
        {
          id: 8,
          name: '生物多样性',
          href: '/software-product-detail/93352ffada6f11ec82ee000c2988cacf',
          left: 1256,
          top: 134
        },
        {
          id: 9,
          name: '碳中和',
          href: '/solution/碳中和解决方案',
          left: 1194,
          top: 38
        },
        {
          id: 10,
          name: '北京冬奥会',
          href: '/winter-olympics',
          left: 647,
          top: 92
        }
      ]
    };
  },
  computed: {
    active() {
      return this.$store.getters.homeActive;
    }
  },
  created() {
    window.addEventListener('keydown', e => {
      if (e.ctrlKey) {
        this.ctrlKey = 1;
      }
    });
    window.addEventListener('keyup', () => {
      this.ctrlKey = 0;
    });
  },
  mounted() {
    //监听鼠标滚动事件
    // window.addEventListener('mousewheel', this.debounce(this.handleScroll, 300), false)
    // this.$refs.swiper.setActiveItem(this.active - 1)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('SET_HOME_ACTIVE', 1);
    next();
  },
  methods: {
    changeCarousel(e) {
      this.$store.commit('SET_HOME_ACTIVE', e + 1);
    },
    changeActive(id) {
      this.$store.commit('SET_HOME_ACTIVE', id);
      this.$refs.swiper.setActiveItem(this.active - 1);
    },
    //函数防抖
    debounce(func, wait) {
      let timeout;
      return function () {
        let context = this;
        let args = arguments;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },
    handleScroll(e) {
      e = e || window.event;
      if (e.wheelDelta) {
        //判断浏览器IE，谷歌滑轮事件
        if (e.wheelDelta > 0) {
          //当滑轮向上滚动时
          if (this.active > 1) {
            this.$store.commit('SET_HOME_ACTIVE', this.active - 1);
          }
        }
        if (e.wheelDelta < 0) {
          //当滑轮向下滚动时
          if (this.active < 3) {
            this.$store.commit('SET_HOME_ACTIVE', this.active + 1);
          }
        }
      } else if (e.detail) {
        //Firefox滑轮事件
        if (e.detail > 0) {
          //当滑轮向上滚动时
          alert('滑轮向上滚动');
        }
        if (e.detail < 0) {
          //当滑轮向下滚动时
          alert('滑轮向下滚动');
        }
      }
    },

    goWheel(event) {
      if (this.ctrlKey === 1) {
        return;
      }
      if (event.deltaY > 0 && this.first) {
        if (this.active === 3) {
          return;
        }
        this.$refs.swiper.next();
        this.$store.commit('SET_HOME_ACTIVE', this.active === 3 ? 1 : this.active + 1);
        this.first = false;
        setTimeout(() => {
          this.first = true;
        }, 500);
      }

      if (event.deltaY < 0 && this.first) {
        if (this.active === 1) {
          return;
        }
        this.$refs.swiper.prev();
        this.$store.commit('SET_HOME_ACTIVE', this.active === 1 ? 3 : this.active - 1);
        this.first = false;
        setTimeout(() => {
          this.first = true;
        }, 500);
      }
    },
    changeIsAutoPlay() {
      this.isAutoPlay = !this.isAutoPlay;
      if (this.isAutoPlay) {
        this.$refs.swiper.next();
      }
    },
    toHref() {
      if (this.activeIndustry.id === 0) {
        return;
      }
      this.$router.push(this.activeIndustry.href);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./Index.scss";
</style>
