<template>
    <div class="solution-product">
        <div v-for="item in list" :key="item.id"
             :class="`product ${active === item.id?' active':''}`"
             @click="active = item.id">
            <img :src="item.photo?item.photo:'/image/solution/solution-default.jpg'"
                 @click="toProductDetail(item)">
            <div class="product-right">
                <div class="product-name" @click="toProductDetail(item)">
                    {{ item.title }}
                </div>
                <div class="product-content" @click="toProductDetail(item)">
                    {{ item.overview }}
                </div>
                <div class="product-href" @click="toProductDetail(item)">
                    了解更多&nbsp;>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {getProductByCode} from '@/api/solution.js'

  export default {
    name: 'solutionProduct',
    props: {
      solution: {}
    },
    watch: {
      solution() {
        this.getProductByCode()
      }
    },
    data() {
      return {
        active: 1,
        list: []
      }
    },
    computed: {},
    mounted() {
      this.getProductByCode()
    },
    methods: {
      // 跳转产品详情页面
      toProductDetail(item) {
        // let params = Object.assign({}, item);
        // this.$router.push({
        //   name: "SoftwareProductDetail",
        //   params: params
        // });
        // this.$router.push(`/software-product-detail/${item.code}`);
        this.$router.push(`/software-product-detail/${item.code}`);
        // if (item.type === 1) {
        //   this.$router.push(`/software-product-detail/${item.code}`);
        // } else {
        //   this.$router.push(`/hardware-product-detail/${item.code}/${item.title}`);
        // }
      },
      //   获取相关产品
      getProductByCode() {
        getProductByCode({SchemeCode: this.solution.code}).then(res => {
          // let softWare = res.data.softWare.map(item => {
          //   return {...item, type: 1}
          // })
          // let hardWare = res.data.hardWare.map(item => {
          //   return {...item, type: 2}
          // })
          // this.list = hardWare.concat(softWare)
          // 产品方案列表改成只显示软件产品了
          this.list = res.data.softWare
          this.active = this.list.length ? this.list[0].code : 1
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
    @import "./Index.scss";
</style>
